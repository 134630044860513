import { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useToast } from '@chakra-ui/react'
import {
	Text,
	Input,
	Box,
	Button,
	InputRightElement,
	InputGroup,
	VStack,
	HStack,
	Divider,
	Center
} from '@chakra-ui/react'
import { useEthersContext } from '../../context'
import { formatToken, formatLocale } from '../../utils'
function ProfitCategory() {
  const { library } = useWeb3React()
  const {isDesktop } = useEthersContext()
	const toast = useToast()
	const space = isDesktop ? "5" : "3";
  const { userInfo, config, token, contracts, approvingContract, isManagerAccess, handleApproveContract } = useEthersContext()
  const [input, setInput] = useState<string>('0')

	const [btnState, setBtnState] = useState<boolean>(false)

  function getReceiveWithdraw(withdraw: number | string) {
		return Number(withdraw) * (1 - Number(config.userWithdrawFee) / 1000)
	}

  const handleWithdrawProfit = async () => {
		toast({
			title: `Withdraw Profit`,
			description: `Withdrawing ${parseFloat(input as string).toFixed(2)} ${token.symbol}...`,
			status: 'info',
			duration: 9000,
			isClosable: true,
		})
		setBtnState(true)
		const amount = (input).toString()
		console.log(`Start withdraw profit: ${amount}`)
		try {
			const amountWei = ethers.utils.parseUnits(amount, token.decimal)
			const tx = await contracts?.botContract.withdrawBunnyBotProfit(amountWei, {
				gasPrice: await library.getGasPrice()
			})
			console.log(`withdraw profit hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`withdraw profit profitest done`)
				toast({
					title: `Withdraw Profit`,
					description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${token.symbol} successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				console.log(`withdraw profit fail`)
				toast({
					title: `Withdraw Profit`,
					description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${token.symbol} failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e:any) {
			toast.closeAll()
			let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message + '. Send some BNB to your wallet'
      }
			toast({
				title: `Withdraw Profit`,
				description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${token.symbol} failed. ${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			console.log(`withdraw profit error: ${JSON.stringify(e)}`)
		}
		setBtnState(false)
		setInput('0')
	}

    return (
      <Box w='full' h='full' bg='white' borderRadius={space} p={space} >
        <VStack w='full' h='full' align='left'>
          <Text as='b'>Profit</Text>
            <HStack w='full' justifyContent='left'>
              <VStack w='full' align='left'>
                <Text color='gray.500'>Current Profit</Text>
                <Text  ml={space}>{formatLocale(userInfo.currentPnL, token)} {token.symbol} {(isManagerAccess)? ((userInfo.currentInvest!=='0')? '('+ (Number(userInfo.currentPnL)/Number(userInfo.currentInvest)*100).toFixed(2) + '%)' : '(0%)')  : '' }</Text>
              </VStack>
              <Center height='50px'>
                <Divider orientation='vertical' />
              </Center>
              <VStack w='full' align='left'>
                <Text color='gray.500'>Total Profit</Text>
                <Text  ml={space}>{formatLocale(userInfo.totalPnL, token)} {token.symbol}</Text>
              </VStack>
            </HStack>
            <Text color='gray.500'>Withdraw</Text>
            <HStack w='full' spacing={5} alignItems='center'>
				<InputGroup>
					<Input
						isInvalid={parseFloat(input) < 0 || parseFloat(input) > parseFloat(formatToken(userInfo.currentPnL, token))}
						disabled={btnState}
						errorBorderColor='red.300'
						type='number'
						name='withdrawProfit'
						value={input}
						onChange={(e)=>{setInput(e.target.value)}}
						placeholder='0.00'
					/>
					<InputRightElement>
						<Button disabled={btnState || approvingContract} onClick={() => { setInput(formatToken(userInfo.currentPnL, token)) }}>
							Max
						</Button>
					</InputRightElement >
				</InputGroup>
				<Button
					w='40%'
					colorScheme='blue'
					isLoading={btnState }
					loadingText={approvingContract ? '': !isDesktop ? '': 'Withdrawing'}
					isDisabled={approvingContract || btnState || parseFloat(input) <= 0 || parseFloat(input) > parseFloat(formatToken(userInfo.currentPnL, token))}
					onClick={userInfo.approve ? handleWithdrawProfit : handleApproveContract}
				>Withdraw</Button>
            </HStack>
            <Text color='gray.500' justifyContent='right' fontSize='13px'>
				Withdraw fee: {Number(config.userWithdrawFee) * 100 / 1000}%. Receive amount: ${getReceiveWithdraw(input)} {token.symbol}
            </Text>
        </VStack>
      </Box>
    )


}

export default ProfitCategory