import React, { useState } from 'react'
import { checkAddress, Constants, getApi, ReferralList, truncateAddress, UserInfo } from '../utils'
import {
	VStack,
	Box,
	Text,
	Avatar,
	Flex,
	Tooltip,
	useDisclosure,
	Button,
	Input,
	HStack,
	useToast,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Spinner,
} from '@chakra-ui/react'

import { FiUser } from "react-icons/fi";
import { Table as TableHistory } from "react-chakra-pagination";
import { useEthersContext } from '../context';
import { SearchUserModal, searchUserData } from './SearchUserInfo';
import { BsCashCoin } from 'react-icons/bs';
import { userInfo } from 'os';

const ReferralHistory = () => {
	const { account, token, isDesktop, isAdminAccess, accessToken, userInfo } = useEthersContext()
	const [referralList, setReferralList] = useState<ReferralList[]>([] as ReferralList[])
	const [page, setPage] = React.useState(1);
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: loading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure()
	const [searchAddress, setSearchAddress] = useState<string>('')
	const [user, setUser] = useState<UserInfo>({} as UserInfo)
	const toast = useToast()

	const getRefferer = async () => {
		onOpenLoading()
		try {
			const refs = await getApi('/referrer', { user: account }, accessToken)
			setReferralList(refs)
		} catch (error) {
			console.log("🚀 ~ getRefferer ~ error:", error)

		}
		onCloseLoading()
	}

	async function searchUser(address: string) {
		if (!checkAddress(address)) {
			toast.closeAll()
			toast({
				title: `Search`,
				description: `Invalid address`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			return
		}
		onOpenLoading()
		const _user = await searchUserData(address, accessToken)
		setUser(_user)
		onCloseLoading()
		onOpen()
	}

	const tableData = referralList.map((user) => ({
		account: (
			<Flex align='center'>
				{
					isAdminAccess ? (
						<>
							<Tooltip label='User info'>
								<Button variant='link' onClick={() => { searchUser(user.address); }}><Avatar size='xs' bg='teal.500' mr="3" /></Button>
							</Tooltip>
							<a href={Constants.explorerUrl + user.address}
								target='_blank' rel="noreferrer"
							>
								<Text as='u'>{(isDesktop) ? user.address : truncateAddress(user.address)}</Text>
							</a>
						</>
					) : (
						<>
							<Avatar size='xs' bg='teal.500' mr="3" />
							<Text>{(isDesktop) ? user.address : truncateAddress(user.address)}</Text>
						</>
					)
				}
			</Flex>
		),
		amount: (Number(user.amount) / 10 ** token.decimal).toFixed(2) + '$'
	}))
	const tableColumns = [
		{
			Header: "Account",
			accessor: "account"
		},
		{
			Header: "Amount",
			accessor: "amount"
		},
	]
	return (
		<>
			<Accordion allowToggle width='full' onChange={getRefferer} bg='white' h='auto'>
				<AccordionItem>
					{({ isExpanded }) => (
						<>
							{
								isExpanded && loading && <Spinner
									style={{ display: 'block', position: "fixed", height: '50px', width: '50px', top: "50%", left: "50%" }}
									thickness='4px'
									speed='0.65s'
									emptyColor='gray.200'
									color='blue.500'
									size='xl'
								/>
							}
							<h2>
								<AccordionButton>
									<Box as="b" flex='1' textAlign='left' >
										<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
											<BsCashCoin size={20} /><Text ml={2}>{userInfo.isNetworkUser ? 'Network Referral' : 'Referral'}</Text>
										</div>
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel >
								<Box w='full' h='auto' bg='white'  >
									<VStack w='full' h='full' align='left'>
										{
											isAdminAccess && (
												<>
													<HStack w='full' spacing='4'>
														<Input
															w='50%'
															type='string'
															name='searchAddress'
															value={searchAddress}
															onChange={(e) => { setSearchAddress(e.target.value) }}
															placeholder='Search user'
														/>
														<Button
															w='150px'
															colorScheme='blue'
															onClick={() => { searchUser(searchAddress) }}
														>
															Search
														</Button>
													</HStack>
													<Text color='gray.500'>Total referrals: {referralList.length}</Text>
												</>
											)
										}
										<TableHistory
											colorScheme="blue"
											// Fallback component when list is empty
											emptyData={{
												icon: FiUser,
												text: "No Referral"
											}}
											totalRegisters={referralList.length}
											page={page}
											// Listen change page event and control the current page using state
											onPageChange={(page) => setPage(page)}
											columns={tableColumns}
											data={tableData}
										/>
									</VStack>
								</Box>

							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>


			<SearchUserModal
				isOpen={isOpen}
				onClose={onClose}
				user={user}
			/>
		</>
	)
}

export default ReferralHistory