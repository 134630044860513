import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider } from '@web3-react/core'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { ethers } from 'ethers'
import { BrowserRouter as Router } from 'react-router-dom'
import { EthersContextProvider } from './context';
const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 10000
  return library
}

const theme = extendTheme({
  components:{
    Drawer:{
      parts: ['dialog', 'header', 'body'],
      variants: {
        primary: {
          dialog: {
            background: '#2B2D3C', 
            color: 'white'
          },
          header: {
            background: '#2B2D3C', 
            color: 'white'
          },
          body: {
            background: '#2B2D3C', 
            color: 'white'
          }
        },
        secondary: {
          dialog: {
            background: 'green'
          }
        }
      },
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <EthersContextProvider>
          <Router>
            <App />
          </Router>
        </EthersContextProvider>
      </Web3ReactProvider>
    </ChakraProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
