// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  height: 60px;
  width: 100vw;
  justify-content: flex-start;
  background-color: #2b2d3c;
  padding: 5px 20px 5px 20px;
  align-items: center;
  color: white;
}

.header_connectWallet {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 20px;
  padding-right: 3px;
}


.header_title {
  display: flex;
  height: 60px;
  align-items: center;
  gap: 20px;
  padding-left: 10px;
}

.token_container{
  display:flex;
  flex-direction: row ;
  justify-content:space-between;
}

.token_container_first {
  flex: 1 1;
}
.token_container_second {
  flex: 1 1;
}

@media screen and (max-width:760px){
  .token_container {
    flex-direction:column
  }
  .token_container_first {
    flex: 2 1;
  }
  .token_container_second {
    flex: 1 1;
  }
 }
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;;;AAGA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,SAAO;AACT;AACA;EACE,SAAO;AACT;;AAEA;EACE;IACE;EACF;EACA;IACE,SAAO;EACT;EACA;IACE,SAAO;EACT;CACD","sourcesContent":[".header {\n  display: flex;\n  height: 60px;\n  width: 100vw;\n  justify-content: flex-start;\n  background-color: #2b2d3c;\n  padding: 5px 20px 5px 20px;\n  align-items: center;\n  color: white;\n}\n\n.header_connectWallet {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  align-items: center;\n  gap: 20px;\n  padding-right: 3px;\n}\n\n\n.header_title {\n  display: flex;\n  height: 60px;\n  align-items: center;\n  gap: 20px;\n  padding-left: 10px;\n}\n\n.token_container{\n  display:flex;\n  flex-direction: row ;\n  justify-content:space-between;\n}\n\n.token_container_first {\n  flex: 1;\n}\n.token_container_second {\n  flex: 1;\n}\n\n@media screen and (max-width:760px){\n  .token_container {\n    flex-direction:column\n  }\n  .token_container_first {\n    flex: 2;\n  }\n  .token_container_second {\n    flex: 1;\n  }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
