import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom'
import Loading from './components/Loading';
import { useEthersContext } from './context';
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

const Home = lazy(() => import('./pages/Home'))
const User = lazy(() => import('./pages/User'))
const Admin = lazy(() => import('./pages/Admin'))
const Register = lazy(() => import('./pages/Register'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Login = lazy(() => import('./pages/Login'))
const Suspended = lazy(() => import('./pages/Suspended'))
const Token = lazy(() => import('./pages/Token'))
const Network = lazy(() => import('./pages/Network'))


function App() {
  const { loadingUser, validChain, validUser } = useEthersContext()
  if (process.env.NODE_ENV === 'production'){
    console.log = function (){}
  }
  return (
    <>
      <Suspense fallback={<Loading/>}>
        <Routes>
          <Route path='/' element={(loadingUser && validChain)? <Loading/> : <Home/>}>
            <Route path='/login' element={(loadingUser && validChain && !validUser) ? <Loading/> : <Login/>}/>
            <Route path='/register' element={loadingUser ? <Loading/> : <Register/>}/>
            <Route path='/user' element={loadingUser ? <Loading/> : <User/>}/>
            <Route path='/admin' element={loadingUser ? <Loading/> : <Admin/>}/>
            <Route path='/suspended' element={loadingUser ? <Loading/> : <Suspended/>}/>
            <Route path='/network' element={loadingUser ? <Loading/> : <Network/>}/>
            <Route path='/exchange' element={loadingUser ? <Loading/> : <User/>}/>
            <Route path='/token' element={loadingUser && validUser ? <Loading/> : <Token/>}/>

          </Route>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
