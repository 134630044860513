import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useToast } from "@chakra-ui/react";
import {
  Text,
  Input,
  Box,
  Button,
  InputRightElement,
  InputGroup,
  VStack,
  HStack,
  Divider,
  Center,
} from "@chakra-ui/react";
import { useEthersContext } from "../../context";
import { formatToken, formatLocale } from "../../utils";
function CommissionCategory() {
  const { library } = useWeb3React();
  const { isDesktop } = useEthersContext()
  const toast = useToast();
  const {
    userInfo,
    config,
    token,
    contracts,
    approvingContract,
    handleApproveContract,
  } = useEthersContext();
  const [input, setInput] = useState<string>('0');
  const space = isDesktop ? "5" : "3";
  const [btnState, setBtnState] = useState<boolean>(false);

  function getReceiveWithdraw(withdraw: number | string) {
    return Number(withdraw) * (1 - Number(config.userWithdrawFee) / 1000);
  }

  const handleWithdrawCommission = async () => {
    toast({
      title: `Withdraw Commission`,
      description: `Withdrawing ${parseFloat(input as string).toFixed(2)} ${
        token.symbol
      }...`,
      status: "info",
      duration: 9000,
      isClosable: true,
    });
    setBtnState(true);
    const amount = input.toString();
    console.log(`Start withdraw commission: ${amount}`);
    try {
      const amountWei = ethers.utils.parseUnits(amount, token.decimal);
      const tx = await contracts?.botContract.withdrawBunnyBotCommission(amountWei, {
        gasPrice: await library.getGasPrice(),
      });
      console.log(`withdraw commission hash: ${tx.hash}`);
      const receipt = await library.waitForTransaction(tx.hash, 1);
      toast.closeAll();
      if (receipt.status) {
        console.log(`withdraw commission done`);
        toast({
          title: `Withdraw Commission`,
          description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${
            token.symbol
          } successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log(`withdraw commission fail`);
        toast({
          title: `Withdraw Commission`,
          description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${
            token.symbol
          } fail`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e: any) {
      toast.closeAll();
      let _e = "";
      if (e.reason !== undefined) {
        _e = e.reason;
      } else if (e.data.message !== undefined) {
        _e = e.data.message + ". Send some BNB to your wallet";
      }
      console.log(`_e: ${JSON.stringify(_e)}`);
      toast({
        title: `Withdraw Commission`,
        description: `Withdraw ${parseFloat(input as string).toFixed(2)} ${
          token.symbol
        } fail. ${_e}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(`withdraw commission error: ${JSON.stringify(e)}`);
    }
    setBtnState(false);
    setInput('0');
  };

  return (
    <Box w="full" h="full" bg="white" borderRadius={space} p={space}>
      <VStack w="full" h="full" align="left">
        <Text as="b">Commission</Text>
        <HStack w="full" justifyContent="left">
          <VStack w="full" align="left">
            <Text color="gray.500">Current Commission</Text>
            <Text ml={space}>
              {formatLocale(userInfo.currentCommission, token)} {token.symbol}
            </Text>
          </VStack>
          <Center height="50px">
            <Divider orientation="vertical" />
          </Center>
          <VStack w="full" align="left">
            <Text color="gray.500">Total Commission</Text>
            <Text ml={space}>
              {formatLocale(userInfo.totalCommission, token)} {token.symbol}
            </Text>
          </VStack>
        </HStack>
        <Text color="gray.500">Withdraw</Text>
        <HStack w="full" spacing={5} alignItems="center">
          <InputGroup>
            <Input
              isInvalid={
                parseFloat(input) < 0 ||
                parseFloat(input) >
                  parseFloat(formatToken(userInfo.currentCommission, token))
              }
              errorBorderColor="red.300"
              type="number"
              name="withdrawCommission"
              min="1"
              max={userInfo.balance}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              placeholder="0.00"
              disabled={approvingContract || btnState}
            />
            <InputRightElement>
              <Button
                disabled={approvingContract || btnState}
                onClick={() => {
                  setInput(formatToken(userInfo.currentCommission, token));
                }}
              >
                Max
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            w="40%"
            colorScheme="blue"
            isLoading={btnState }
            loadingText={approvingContract ? "" : !isDesktop ? '': "Withdrawing"}
            isDisabled={
              approvingContract ||
              btnState ||
              parseFloat(input) <= 0 ||
              parseFloat(input) > parseFloat(formatToken(userInfo.currentCommission, token))
            }
            onClick={
              userInfo.approve
                ? handleWithdrawCommission
                : handleApproveContract
            }
          >
            Withdraw
          </Button>
        </HStack>
        <Text color="gray.500" fontSize="13px">
          Withdraw fee: {(Number(config.userWithdrawFee) * 100) / 1000}%.
          Receive amount: ${getReceiveWithdraw(input)} {token.symbol}
        </Text>
      </VStack>
    </Box>
  );
}

export default CommissionCategory;
