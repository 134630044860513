
import {
  Box,
  HStack,
} from '@chakra-ui/react'

import { useState } from 'react'
import { selectBorder, selectPadding, AdminConfigInterface, getApi } from '../utils'
import { useEthersContext } from '../context'
import AdminConfigParameters from './AdminConfigParameters'
import AdminConfigSetConfig from './AdminConfigSetConfig'
export default function AdminConfig() {
  const { isDesktop, isAdminAccess, accessToken } = useEthersContext()
	const [adminConfig, setAdminConfig ] = useState<AdminConfigInterface>({
    profitPercent: 0.0,
    minProfitBalance: 0.0,
    totalDistribute: 0.0,
    websiteInterval: 0.0,
    profitInterval: 0.0,
    withdrawInterval: 0.0,
    reserveInterval: 0.0,
    autoProfitInterval: 0.0,
    minDistributeTime: 0.0,
    maxDistributeTime: 0.0,
    paceDistributeTime: 0.0,
    expandParam: 0.0,
    autoProfitCurrentInvest: 0.0,
    autoProfitBotBalance: 0.0, 
  } as AdminConfigInterface)


  function handleAdminConfig (name: string, value: number | string) {
    setAdminConfig(prev => ({
     ...prev,
      [name]: value,
    }))
  }

  async function getAdminConfig() {
    console.log(`getAdminConfig`)
		const res = await getApi('/admin/getConfig',{}, accessToken)
    Object.keys(adminConfig).map( (item, index) => handleAdminConfig(item, res[index]))
	}

  return (
    <>
    
        <Box w='full' h='auto' bg='white' alignItems='left' borderRadius={selectBorder(isDesktop)} p={selectPadding(isDesktop)} pt={isAdminAccess? selectPadding(isDesktop): 7}>
        {
          <>
          {isAdminAccess && <AdminConfigParameters adminConfig={adminConfig} getAdminConfig={getAdminConfig}/>}
          <AdminConfigSetConfig adminConfig={adminConfig} getAdminConfig={getAdminConfig}/>
          </>
        }
          
        </Box>
      
    </>
    
  )
}
