import {
	Box,
	Button,
	Text,
	VStack,
	Divider,
	Flex,
	HStack,
	InputGroup,
	Input,
	useToast,
	InputLeftElement,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Table,
	Tbody,
	Tr,
	Td,
	Th,
	Spinner,
	Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
	useDisclosure,
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'
import { useEthersContext } from '../context'
import { ethers, BigNumber } from 'ethers'
import { selectPadding, getApi, formatToken, Constants, floatToken, truncateAddress, formatLocale } from '../utils'
import { Table as TableHistory } from "react-chakra-pagination";
import './styles.css'
import { FiUser } from 'react-icons/fi'
import { BiPurchaseTag } from 'react-icons/bi'
import { BsCashCoin, BsInfoCircle } from 'react-icons/bs'
import { TransferBFiModal } from './Modal'
function TokenBFi() {
  const { isDesktop, account, token, accessToken, userInfo, tokenSale } = useEthersContext()
	const { library } = useWeb3React()
	const space = selectPadding(isDesktop)
	const [ input, setInput ] = useState<string>('0')
	const [ btnState, setBtnState ] = useState<boolean>(false)
	const [ btnClaimState, setBtnClaimState ] = useState<boolean>(false)
	const [ btnClaimIIBState, setBtnClaimIIBState ] = useState<boolean>(false)
	const toast = useToast()
	const [approvingContract, setApprovingContract] = useState<boolean>(false)
	const [ bonusHistory, setBonusHistory ] = useState<string[]>([] as string[])
	const [ page, setPage ] = useState<number>(1);
	const [ loading, setLoading ] = useState<boolean>(false)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [ transfering, setTransfering ] = useState<boolean>(false)

	const tableData = bonusHistory.map((user) => ({
		account: (
			<Flex align='center'>
						<>
						  <Text>{(isDesktop) ? user[0] : truncateAddress(user[0])}</Text>
						</>
			</Flex>
		),
		bonus: parseFloat(formatToken(user[1], token)).toFixed(3),
		bonusUSD: parseFloat(formatToken(user[2], token)).toFixed(2)
	}))
	const tableColumns = [
		{
			Header: "Account",
			accessor: "account"
		},
		{
			Header: "BFi",
			accessor: "bonus"
		},
		{
			Header: token.symbol,
			accessor: "bonusUSD"
		},
	]


	const getBonusHistory = async() => {
		setLoading(true)
		try {
			console.log(`getBonusHistory`)
			const res = await getApi('/token/getBonusOfUser', { user: account}, accessToken)
			// console.log(JSON.stringify(res, null,4))
			if (res.length)
				setBonusHistory(res)
		} catch (error) {
			console.log("🚀 ~ file: TokenBFi.tsx:133 ~ getBonusHistory ~ error:", error)
		}
		setLoading(false)
	}

	async function handleApproveContract() {
		toast({
			title: `Approve Buy Token`,
			description: `Approving Buy Token`,
			status: 'loading',
			duration: 3000,
			isClosable: true,
		})
    	setApprovingContract(true)
		const signer = library.getSigner()
		const usdContract = new ethers.Contract(Constants.tokenAddress as string, Constants.erc20Abi, signer)
		try {
			const tx = await usdContract.approve(Constants.manageTokenContractAddress, ethers.constants.MaxUint256, {
				gasPrice: await library.getGasPrice()
			})
			// console.log(`Approve hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Approve Buy Token done`)
				toast({
					title: `Approve Buy Token`,
					description: `Contract is approved successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				console.log(`Approve Buy Token fail`)
				toast({
					title: `Approve Buy Token`,
					description: `Approve Buy Token failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e:any) {
      let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message
      }
			console.log(`Approve Buy Token error: ${JSON.stringify(e)}`)
			toast.closeAll()
			toast({
				title: `Approve Buy Token`,
				description: `Approve Buy Token failed. ${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
		}
		setApprovingContract(false)
	}
	
	const handleTokenTrade = async() => {
		console.log(`handleTokenTrade: Buy token`)
		setBtnState(true)
		const signer = library.getSigner()
		const contract = new ethers.Contract(Constants.manageTokenContractAddress, Constants.contractManageTokenAbi, signer)
		try {
			const amount = ethers.utils.parseUnits(input.toString(), token.decimal)
			const tx = await contract.bunnybotTransferOwnerToken(amount, {
				gasPrice: await library.getGasPrice()
			})
			// console.log(`Approve hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Buy token done`)
				toast({
					title: `Buy token`,
					description: `Trade token successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
				tokenSale.getTokenSaleConfig()
				tokenSale.getTokenSaleUser()
			} else {
				console.log(`Buy token fail`)
				toast({
					title: `Buy token`,
					description: `Buy token failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e: any) {
			let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message + '. Send some BNB to your wallet'
      }
      setApprovingContract(false)
			console.log(`Buy token error: ${JSON.stringify(e.reason)}`)
			toast.closeAll()
			toast({
				title: `Buy token`,
				description: `${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
	}
		setBtnState(false)
	}

	async function handleClaimIB(type: string) {
		console.log(`handleClaimIB: Claim usdc`)
		const _compareType = 'IB'
		if (type === _compareType)
			setBtnClaimState(true)
		else 
			setBtnClaimIIBState(true)
		const signer = library.getSigner()
		const contract = new ethers.Contract(Constants.manageTokenContractAddress, Constants.contractManageTokenAbi, signer)
		try {
			const amount = floatToken(type === _compareType? tokenSale.data.user.ibBonusUSD : tokenSale.data.user.ibIIBBonusUSD , token)
			let tx
			if (type === _compareType){
				tx = await contract.bunnyBotClaimIB({
					gasPrice: await library.getGasPrice()
				})
			} else {
				tx = await contract.bunnyBotClaimIIB({
          gasPrice: await library.getGasPrice()
        })
			}
			
			// console.log(`Approve hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Claim ${_compareType}`)
				toast({
					title: `Claim ${_compareType}`,
					description: `Claim ${amount} USDC successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
				tokenSale.getTokenSaleConfig()
				tokenSale.getTokenSaleUser()
			} else {
				console.log(`Claim ${_compareType}`)
				toast({
					title: `Claim ${_compareType}`,
					description: `Claim ${_compareType} failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e: any) {
			let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message + '. Send some BNB to your wallet'
      }
			console.log(`Claim ${_compareType}: ${JSON.stringify(e.reason)}`)
			toast.closeAll()
			toast({
				title: `Claim ${_compareType}`,
				description: `${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
		}
		if (type === _compareType)
			setBtnClaimState(false)
		else 
      setBtnClaimIIBState(false)
	}

	function checkAddress(address: string) {
    const valid = ethers.utils.isAddress(address)
    if (!valid) {
      toast.closeAll()
      toast({
        title: `Invalid Address`,
        description: `${address}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setTransfering(false)
    }
    return valid
  }

	async function handleTransferBFiToken(receiver: string, amount: string) {
		setTransfering(true)
		console.log(`handleTransferBFiToken: handleTransferBFiToken`)
		const signer = library.getSigner()
		const contract = new ethers.Contract(Constants.manageTokenContractAddress, Constants.contractManageTokenAbi, signer)
		try {
			const _amount = ethers.utils.parseUnits(amount, token.decimal)
			if (!checkAddress(receiver)) 
				return
			const tx = await contract.bunnybotTransferTokenInternal(receiver , _amount, {
          gasPrice: await library.getGasPrice()
      })
			
			// console.log(`Approve hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`handleTransferBFiToken: success`)
				toast({
					title: `Transfer BFi Token`,
					description: `Transfer ${amount} BFi successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
				tokenSale.getTokenSaleConfig()
				tokenSale.getTokenSaleUser()
			} else {
				console.log(`handleTransferBFiToken: failed`)
				toast({
					title: `Transfer BFi Token`,
					description: `Transfer BFi failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e: any) {
			let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message + '. Send some BNB to your wallet'
      }
			console.log(`handleTransferBFiToken: ${JSON.stringify(e.reason)}`)
			toast.closeAll()
			toast({
				title: `Transfer BFi Token`,
				description: `${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
		}
		setTransfering(false)
	}

	if (tokenSale.data.config.maxSellToken === '' || tokenSale.data.config.tokenPrice === '') {
		return (<></>)
	} else
	return (
	<VStack w='full' h='auto' mt={space} spacing={space}>
			<Box w='full' h='full' bg='white' borderRadius={space} p={space} >
				<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
					<Text mr={2}>Token Info</Text>
					<a href="https://bunnybot.io/wiki/detail/129/bfitokeninfo" target='_blank' rel="noreferrer"><BsInfoCircle size={15}/></a>
					
				</div>	
				<div className='token_container'>
					<div className='token_container_first'>
						<Flex w={isDesktop ? '90%': 'full'} pt={space} pl={space} pr={space} pb={1}>
							<Text flex='1' as='b'>BFi locked</Text>
							<Text flex='1' textAlign='right' as='b'>
								{formatLocale(BigNumber.from(tokenSale.data.user.balance)
									.add(tokenSale.data.user.totalUserBonus)
									.add((tokenSale.data.user.isIB || tokenSale.data.config.ibIIBUsers.includes(account!))? tokenSale.data.user.ibBonus : 0).toString(), token)} BFi
							</Text>
						</Flex>

						<Flex w={isDesktop ? '90%': 'full'} pl={space + 3} pr={space} color='gray.500'>
							<Text flex='1'>Purchased</Text>
							<Text flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.balance, token)} BFi</Text>
						</Flex>
						<Flex w={isDesktop ? '90%': 'full'} pl={space + 3} pr={space} color='gray.500'>
							<Text flex='1'>Bonus BFi</Text>
							<Text flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.totalUserBonus, token)} BFi</Text>
						</Flex>
						{
							(tokenSale.data.user.isIB || tokenSale.data.config.ibIIBUsers.includes(account!)) && 
							<Flex w={isDesktop ? '90%': 'full'} pl={space + 3} pr={space} color='gray.500'>
								<Text flex='1'>IB Extra</Text>
								<Text flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.ibBonus, token)} BFi</Text>
							</Flex>
						}
						{
							(tokenSale.data.config.ibIIBUsers.includes(account!)) && (
								<>
								<Divider orientation='horizontal' w={isDesktop ? '80%': '90%' } pt={3} ml={space+3}/>
								<Flex w={isDesktop ? '90%': 'full'} pl={space + 3} pr={space} color='gray.500' pt={3}>
									<Text flex='1'>IB Internal</Text>
									<Text flex='1' textAlign='right'>
										{formatLocale(tokenSale.data.user.ibIIBBonusUSD, token)} {token.symbol}</Text>
								</Flex>
								<Flex w={isDesktop ? '90%': 'full'} pl={space + 3} pr={space} color='gray.500'  pb={space}>
									<Text flex='1'>Payout ratio</Text>
									<Text flex='1' textAlign='right'>
										{(parseFloat(tokenSale.data.user.ibPayoutRatio)/10**21).toFixed(6)}</Text>
								</Flex>
								</>
							)
						}
						<Divider orientation='horizontal' w={isDesktop ? '90%': 'full'}/>
						<Flex w={isDesktop ? '90%': 'full'} p={space}>
							<Text flex='1'>BFi locked value</Text>
							<Text flex='1' textAlign='right'>{formatLocale((BigNumber.from(tokenSale.data.user.totalUserBonus)
								.add(tokenSale.data.user.balance)
								.add((tokenSale.data.user.isIB || tokenSale.data.config.ibIIBUsers.includes(account!))? tokenSale.data.user.ibBonus : 0))
								.mul(tokenSale.data.config.tokenPrice).div(1000).toString(), token)} {token.symbol}</Text>
						</Flex>

						<Divider orientation='horizontal' w={isDesktop ? '90%': 'full'} />
						<Flex w={isDesktop ? '90%': 'full'} p={space} >
							<Text flex='1'>Referral earned</Text>
							<Text flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.totalUSDBonus, token)} {token.symbol}</Text>
						</Flex>

						{
							(tokenSale.data.user.isIB || tokenSale.data.config.ibIIBUsers.includes(account!)) && (
								<>
								<Divider orientation='horizontal' w={isDesktop ? '90%': 'full'} />
								<Flex w={isDesktop ? '90%': 'full'} pt={space} pl={space} pr={space} pb={1} >
									<Text flex='1'>IB Extra</Text>
									<Text as='b' color='gray.500' flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.ibBonusUSD, token)} {token.symbol}</Text>
								</Flex>
								<Flex w={isDesktop ? '90%': 'full'} pb={space} pl={space + 3} pr={space} alignItems='center' justifyContent='flex-end' flexDir='row'>
									<Flex flexDir='column' flex='3'>
										<Text flex='1' color='gray.500'>IB Claimed</Text>
										<Text flex='1' color='gray.500'>{formatLocale(tokenSale.data.user.ibTotalClaimed, token)} BFi | <b>{formatLocale(tokenSale.data.user.ibTotalClaimedUSD, token)} {token.symbol}</b></Text>
									</Flex>
									<Button
										w={isDesktop ? '100px' : '70px'}
										h='30px'
										colorScheme='blue'
										isLoading={btnClaimState}
										loadingText={isDesktop ? 'Claiming' : ''}
										isDisabled = {btnClaimState || tokenSale.data.user.ibBonusUSD === '0' || !tokenSale.data.user.isIB}
										type="submit"
										onClick={()=>{handleClaimIB('IB')}}
									>Claim
									</Button>
								</Flex>
								</>
							)
						}

						{
							tokenSale.data.config.ibIIBUsers.includes(account!) && (
								<>
								<Divider orientation='horizontal' w={isDesktop ? '90%': 'full'} />
								<Flex w={isDesktop ? '90%': 'full'} pt={space} pl={space} pr={space} pb={1} >
									<Text flex='1'>IB Internal </Text>
									<Text as='b' color='gray.500' flex='1' textAlign='right'>{formatLocale(tokenSale.data.user.ibIIBBonusUSD, token)} {token.symbol}</Text>
								</Flex>
								<Flex w={isDesktop ? '90%': 'full'} pb={space} pl={space + 3} pr={space} alignItems='center' justifyContent='flex-end' flexDir='row'>
									<Flex flexDir='column' flex='3'>
										<Text flex='1' color='gray.500'>IB Claimed</Text>
										<Text flex='1' color='gray.500'><b>{formatLocale(tokenSale.data.user.ibIIBTotalClaimedUSD, token)} {token.symbol}</b></Text>
									</Flex>
									<Button
										w={isDesktop ? '100px' : '70px'}
										h='30px'
										colorScheme='blue'
										isLoading={btnClaimIIBState}
										loadingText={isDesktop ? 'Claiming' : ''}
										isDisabled = {btnClaimIIBState || 
											tokenSale.data.user.ibIIBBonusUSD === '0' || 
											BigNumber.from(tokenSale.data.user.totalUSDBonus).lt(BigNumber.from(ethers.utils.parseEther('2000')))}
										type="submit"
										onClick={()=>{handleClaimIB('Internal IB')}}
									>
										Claim
									</Button>
								</Flex>
								</>
							)
						}

						<Divider orientation='horizontal' w={isDesktop ? '90%': 'full'}/>
						<Flex p={space} justifyContent='space-between' alignItems='center' w={isDesktop ? '90%': 'full'}>
							<Text >Transfer Purchased BFi</Text>
							<Button
								w={isDesktop ? '100px' : '70px'}
								h='30px'
								colorScheme='blue'
								onClick={()=>{onOpen()}}
								isDisabled={tokenSale.data.user.balance === '0'}
								isLoading={transfering}
							>
								Transfer
							</Button>
						</Flex>
							
						{ !isDesktop && <Divider orientation='horizontal' w='full' mb={space}/>}
					</div>

					<div className='token_container_second'>
						<Flex alignItems= 'center' flexWrap='wrap' w={isDesktop ? '90%': 'full'} pt={space}  pb={isDesktop ? '1': space}>
							<Text ml={isDesktop ? '0' : space}>BFi PreSale</Text>
							<>
							<Popover placement='bottom'>
								<PopoverTrigger>
									<Button variant='link' color='black'><BsInfoCircle size={15}/></Button>
								</PopoverTrigger>
								<PopoverContent color='white' bg='#2b2d3c' borderColor='#2b2d3c'>
									<PopoverHeader pt={4} fontWeight='bold' border='0'>
										BFi Token PreSale
									</PopoverHeader>
									<PopoverArrow />
									<PopoverCloseButton />
									<PopoverBody >
										<Divider orientation='horizontal' w='full' color='white' mb={2}/>
										<Flex>
											<Text flex='1'>Total Sale</Text>
											<Text flex='1'>{tokenSale.data.config.presaleTotal} BFi</Text>
										</Flex>
										<Flex>
											<Text flex='1'>Price</Text>
											<Text flex='1'>{tokenSale.data.config.presalePrice} USDC</Text>
										</Flex>
									</PopoverBody>
								</PopoverContent>
							</Popover>
							</>
						</Flex>	
						{/* <Text pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
							Total Sold: {formatLocale(tokenSale.data.config.totalSellToken, token)} BFi
						</Text>
						<Text pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
							Available: {formatLocale(tokenSale.data.config.availableSellToken, token)} BFi
						</Text> */}
						<Text pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
							Price: 1 BFi = {parseFloat(tokenSale.data.config.tokenPrice)/1000} {token.symbol}
						</Text>
						{
							parseFloat(input) > 0 && 
							<Text as='i' fontSize='14px' pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
								{Number(input).toFixed(2)} {token.symbol} = {(Number(input)*1000/Number(tokenSale.data.config.tokenPrice)).toFixed(2)} BFi
							</Text>
						}

						<HStack w={isDesktop ? '90%': 'full'} h='auto'  mb={space} mt={space}>						
							{
								tokenSale.data.config.freezing ? (
									<>
									<Text textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='red.500'>
										Buying BFi token is unavailable now. Please come back in next round!
									</Text>
									</>
									
								) : (
									<>
									<InputGroup flex='1' ml={isDesktop ? '0': space}>
										<Input
											textAlign='right'
											mr={space}
											isInvalid={parseFloat(input) < 0 || parseFloat(input) > parseFloat(formatToken(tokenSale.data.config.availableSellToken, token))* parseFloat(tokenSale.data.config.tokenPrice)/1000}
											errorBorderColor='red.300'
											type='number'
											name='trade'
											value={input}
											onChange={(e)=>{setInput(e.target.value)}}
											placeholder={`Enter amount of ${token.symbol}`}
											disabled={btnState || approvingContract }
										/>
										<InputLeftElement mr={space} >
											<Button disabled={btnState || approvingContract} onClick={() => { 
												setInput(formatToken(BigNumber.from(userInfo.balance).gt(BigNumber.from(tokenSale.data.config.availableSellToken).mul(tokenSale.data.config.tokenPrice).div(1000)) ? BigNumber.from(tokenSale.data.config.availableSellToken).mul(tokenSale.data.config.tokenPrice).div(1000).toString() : userInfo.balance , token)) }}>
												Max
											</Button>
										</InputLeftElement >
									</InputGroup>

									<Button
										w={isDesktop ? '200px': '30%'}
										colorScheme='blue'
										isLoading={btnState || approvingContract}
										loadingText={ approvingContract ? 'Approving': 'Buying'}
										isDisabled = {
											btnState || approvingContract || parseFloat(input) <= 0 || 
											parseFloat(input) > parseFloat(formatToken(tokenSale.data.config.availableSellToken, token)) * parseFloat(tokenSale.data.config.tokenPrice)/1000 ||
											input === '' || parseFloat(input) > parseFloat(formatToken(userInfo.balance, token))
										}
										type="submit"
										onClick={() => {
											if (tokenSale.data.user.approve) {
												console.log('token trading')
												handleTokenTrade()
											}
											else {
												console.log('token trade approve')
												handleApproveContract()
											}
										}
										}
									>Buy
									</Button>
									</>
								)
							}
						</HStack>
						<Text fontSize='13px' textAlign='left' ml={isDesktop ? '0': space} >
								Get bonus from <a href="https://bunnybot.io/wiki/detail/130/referralprogram" target='_blank' rel="noreferrer">
									<span style={{color: 'blue'}}><u>Referral Program</u></span></a>
						</Text>
						<Divider mt='10' orientation='horizontal' w={isDesktop ? '90%': 'full'} />
						<Text mt='5' ml={isDesktop ? '0' : space}>Dividend</Text>
						<Text pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
							Current: 0.00 {token.symbol}
						</Text>
						<Text pl={space} w={isDesktop ? '90%': 'full'} textAlign='left' ml={isDesktop ? '0': space} mb={isDesktop ? '0': space} color='gray.500'>
							Total:   0.00 {token.symbol}
						</Text>
						<HStack w={isDesktop ? '90%': 'full'} h='auto'  mb={space} mt={space}>						
							<InputGroup flex='1' ml={isDesktop ? '0': space}>
								<Input
									textAlign='right'
									mr={space}
									isInvalid={parseFloat(input) < 0 || parseFloat(input) > parseFloat(formatToken(tokenSale.data.config.availableSellToken, token))* parseFloat(tokenSale.data.config.tokenPrice)/1000}
									errorBorderColor='red.300'
									type='number'
									name='trade'
									value={input}
									onChange={(e)=>{setInput(e.target.value)}}
									placeholder={`Enter amount of ${token.symbol}`}
									disabled={btnState || approvingContract }
								/>
								<InputLeftElement mr={space} >
									<Button disabled={btnState || approvingContract} onClick={() => { 
										setInput(formatToken(BigNumber.from(userInfo.balance).gt(BigNumber.from(tokenSale.data.config.availableSellToken).mul(tokenSale.data.config.tokenPrice).div(1000)) ? BigNumber.from(tokenSale.data.config.availableSellToken).mul(tokenSale.data.config.tokenPrice).div(1000).toString() : userInfo.balance , token)) }}>
										Max
									</Button>
								</InputLeftElement >
							</InputGroup>

							<Button
								w={isDesktop ? '200px': '30%'}
								colorScheme='blue'
								isLoading={btnState || approvingContract}
								loadingText={ approvingContract ? 'Approving': 'Buying'}
								isDisabled = {
									btnState || approvingContract || parseFloat(input) <= 0 || 
									parseFloat(input) > parseFloat(formatToken(tokenSale.data.config.availableSellToken, token)) * parseFloat(tokenSale.data.config.tokenPrice)/1000 ||
									input === '' || parseFloat(input) > parseFloat(formatToken(userInfo.balance, token))
								}
								type="submit"
								onClick={() => {
									if (tokenSale.data.user.approve) {
										console.log('token trading')
										handleTokenTrade()
									}
									else {
										console.log('token trade approve')
										handleApproveContract()
									}
								}
								}
							>Withdraw
							</Button>
						</HStack>
					</div>
				</div>
				<Accordion allowMultiple width='full' onChange={()=>{getBonusHistory()}} bg='white' mt={isDesktop ? '0': space}>
					<AccordionItem>
						{({ isExpanded }) => (
						<>
							{
								isExpanded && loading && 
									<Spinner 
										style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
										thickness='4px'
										speed='0.65s'
										emptyColor='gray.200'
										color='blue.500'
										size='xl' 
									/> 
							}
							<h2>
							<AccordionButton>
								<Box as="b" flex='1' textAlign='left' >									
									<div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
										<BiPurchaseTag  size={15}/><Text ml={2}>Purchased</Text>
									</div>			
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel >
								<Text textAlign='left'  color='gray.500' ml={space}>
									Purchased USD: {formatLocale(tokenSale.data.user.totalUSDBuy, token)} {token.symbol}
								</Text>
							{
									(tokenSale.data.user.history.length > 0) ? 
									<Table size={isDesktop? 'md' : 'sm'} align='center'>
										<Tbody>
											<Tr>
												<Th>Amount</Th>
												<Th>Price</Th>
												<Th>BFi</Th>
											</Tr>
											{
												tokenSale.data.user.history.map((trade: string, i: number) => {
													let _data = trade.split(',')
													return (
														<Tr key={i}>
															<Td >{parseFloat(formatToken(_data[0], token)).toFixed(2)}</Td>
															<Td >{_data[1]}</Td>
															<Td >{parseFloat(formatToken(_data[2], token)).toFixed(2)}</Td>
														</Tr>
													)
												}) 
											}
										</Tbody>
									</Table>
									: (
										<>
											<Text textAlign='center'>No history</Text>
										</>
									)
								}		
							</AccordionPanel>
						</>
						)}
					</AccordionItem>

					<AccordionItem>
						{({ isExpanded }) => (
						<>
							{
								isExpanded && loading && 
									<Spinner 
										style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
										thickness='4px'
										speed='0.65s'
										emptyColor='gray.200'
										color='blue.500'
										size='xl' 
									/> 
							}
							<h2>
							<AccordionButton>
								<Box as="b" flex='1' textAlign='left' >
									<div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
										<BsCashCoin size={15}/><Text ml={2}>Referral</Text>
									</div>		
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel >
								<TableHistory
									colorScheme="blue"
									// Fallback component when list is empty
									emptyData={{
										icon: FiUser,
										text: "No Referral"
									}}
									totalRegisters={bonusHistory.length}
									page={page}
									// Listen change page event and control the current page using state
									onPageChange={(page) => setPage(page)}
									columns={tableColumns}
									data={tableData}
								/>
							</AccordionPanel>
						</>
						)}
					</AccordionItem>
				</Accordion>

				<TransferBFiModal isOpen={isOpen} onClose={onClose} onConfirm={handleTransferBFiToken}/>

			</Box>
		</VStack>
	)
}

export default TokenBFi