import { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import {
	Button,
	Text,
	VStack,
	HStack,
	Flex,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	Input,
	Divider,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import { AdminConfigInterface, getApi, truncateAddress } from '../utils'
import { useEthersContext } from '../context'

interface ChildManagerInterface {
	id: string,
	childManager: boolean,
	requestChildManager: boolean,
	botName: string,
	outTokenSymbol: string,
	tradeRandomInterval: number,
	tradeRandomChildsNumber: number,
	tradeRandomPercent: number,
	profitRandomInterval: number,
	tokenOutContractSwapThreshold: string,
	tokenOutContractSwapPercent: string,
	tokenPerChild: number,
	feePerChild: string,
	totalChilds: number
}



function AdminConfigSetConfig({ adminConfig, getAdminConfig }: { adminConfig: AdminConfigInterface, getAdminConfig: () => void }) {
	const { userInfo, token, contracts, isAdminAccess, accessToken, isDesktop } = useEthersContext()
	const { library } = useWeb3React()
	const toast = useToast()
	const [adminDistribution, setAdminDistribution] = useState<string[]>(['0', '0', '0'])
	const [profitUsd, setProfitUsd] = useState<string | number>(0)
	const [childManagers, setChildManagers] = useState<ChildManagerInterface[]>([] as ChildManagerInterface[])

	//The parameter order is important to
	const menuItems = [
		'profitPercent',
		'minProfitBalance',
		'totalDistribute',
		'websiteInterval',
		'profitInterval',
		'withdrawInterval',
		'reserveInterval',
		'autoProfitInterval',
		'minDistributeTime',
		'maxDistributeTime',
		'paceDistributeTime',
		'expandParam'
	]

	const childManagerMenuItems = [
		'botName',
		'outTokenSymbol',
		'tradeRandomInterval',
		'tradeRandomChildsNumber',
		'tradeRandomPercent',
		'profitRandomInterval',
		'tokenOutContractSwapThreshold',
		'tokenOutContractSwapPercent',
		'tokenPerChild',
		'feePerChild',
		'totalChilds'
	]
	
	const [adminConfigInput, setAdminConfigInput] = useState({
		type: '',
		value: 0,
	})

	const [childManagerConfigInput, setChildManagerConfigInput] = useState({
		botName: '',
		param: '',
		value: 0,
	})

	function handleChidManagerConfigInput(name: string, value: number | string | boolean) {
		setChildManagerConfigInput(prev => ({
			...prev,
			[name]: value,
		}))
	}

	function handleAdminConfigInput(name: string, value: number | string | boolean) {
		setAdminConfigInput(prev => ({
			...prev,
			[name]: value,
		}))
	}


	async function processProfit() {
		toast({
			title: `Process profit`,
			description: `Processing ${profitUsd} ${token.symbol} profit`,
			status: 'info',
			duration: 9000,
			isClosable: true,
		})
		try {
			const tx = await contracts?.botContract?.collectAndDistributeProfit(ethers.utils.parseUnits(profitUsd.toString(), token.decimal), {
				gasPrice: await library.getGasPrice()
			})
			console.log(`Process profit hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Process profit success`)
				toast.closeAll()
				toast({
					title: `Process Profit`,
					description: `Processing ${profitUsd} ${token.symbol} profit`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				toast.closeAll()
				toast({
					title: `Process Withdrawal`,
					description: `Processing profit failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
				console.log(`Process profit failed`)
			}
		} catch (e) {
			toast.closeAll()
			toast({
				title: `Process profit`,
				description: `Processing profit failed`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			console.log(e)
		}
	}

	async function getChildManagers() {
		console.log(`getChildManagers`)
		try {
			const res = await getApi('/admin/getChildManagers', {}, accessToken)
			console.log(`getChildManagers: ${JSON.stringify(res, null, 2)}`)
			setChildManagers(res)
		} catch (error) {
			console.log("getChildManagers ~ error:", error)

		}
	}

	async function approveChildManager(childManagerId: string) {
		toast({
			title: `Approve Child Manager`,
			description: `Approving ${childManagerId} as child manager`,
			status: 'info',
			duration: 9000,
			isClosable: true,
		})
		try {
			const tx = await contracts?.childContract?.approveChildManager(childManagerId, {
				gasPrice: await library.getGasPrice()
			})
			console.log(`Approve Child Manager hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Approve Child Manager success`)
				toast.closeAll()
				toast({
					title: `Approve Child Manager`,
					description: `Approving ${childManagerId} as child manager success`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
				await getChildManagers()
			} else {
				toast.closeAll()
				toast({
					title: `Approve Child Manager`,
					description: `Approving ${childManagerId} as child manager failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
				console.log(`Approve Child Manager failed`)
			}
		} catch (e) {
			toast.closeAll()
			toast({
				title: `Approve Child Manager`,
				description: `Approving ${childManagerId} as child manager failed`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			console.log(e)
		}

	}

	async function rejectChildManager(childManagerId: string) {
		toast({
			title: `Reject Child Manager`,
			description: `Rejecting ${childManagerId} as child manager`,
			status: 'info',
			duration: 9000,
			isClosable: true,
		})
		try {
			const tx = await contracts?.childContract?.removeChildManager(childManagerId, {
				gasPrice: await library.getGasPrice()
			})
			console.log(`Reject Child Manager hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Reject Child Manager success`)
				toast.closeAll()
				toast({
					title: `Reject Child Manager`,
					description: `Rejecting ${childManagerId} as child manager success`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
				await getChildManagers()
			} else {
				toast.closeAll()
				toast({
					title: `Reject Child Manager`,
					description: `Rejecting ${childManagerId} as child manager failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
				console.log(`Reject Child Manager failed`)
			}
		} catch (e) {
			toast.closeAll()
			toast({
				title: `Reject Child Manager`,
				description: `Rejecting ${childManagerId} as child manager failed`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			console.log(e)
		}

	}

	async function updateAdminConfig() {
		try {
			var _param = adminConfigInput.type

			const res = await getApi('/admin/setUpdate', { param: _param, value: adminConfigInput.value }, accessToken)
			console.log(`updateAdminConfig ${_param}: ${res}`)
			if (res.length !== 0) {
				getAdminConfig()
				toast.closeAll()
				toast({
					title: `Admin Update`,
					description: `Set ${_param}: ${adminConfigInput.value} success`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				toast.closeAll()
				toast({
					title: `Admin Update`,
					description: `Set param failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		} catch (error) {
			console.log("updateAdminConfig ~ error:", error)

		}

	}

	async function updateChildManagerConfig() {	
		try {
			var _param = childManagerConfigInput.param
			var _botName = childManagerConfigInput.botName
			const res = await getApi('/admin/setChildManagerParamUpdate', { param: _param, value: childManagerConfigInput.value, botName: _botName }, accessToken)
			console.log(`updateChildManagerConfig ${_param}: ${res}`)
			if (res.result) {
				getChildManagers()
				toast.closeAll()
				toast({
					title: `Child Manager Update`,
					description: `Set ${_param}: ${childManagerConfigInput.value} success`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				toast.closeAll()
				toast({
					title: `Child Manager Update`,
					description: `Set param failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		} catch (error) {
			console.log("updateChildManagerConfig ~ error:", error)

		}

	}

	async function getAdminDistribution() {
		console.log(`getAdminDistribution`)
		try {
			const res = await getApi('/admin/getDistribution', {}, accessToken)
			setAdminDistribution(res)
		} catch (error) {
			console.log("getAdminDistribution ~ error:", error)

		}
	}




	return (
		<>
			<Flex w='full' h='auto' flexDir='column'>
				<Accordion allowToggle width='full' onChange={() => { getAdminConfig(); getAdminDistribution(); getChildManagers(); }} bg='white' pb={2} mt={isAdminAccess ? 2 : 0}>
					<AccordionItem>
						{({ isExpanded }) => (
							<>
								<h2>
									<AccordionButton>
										<Box as="b" flex='1' textAlign='left' >
											Admin Config
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel >
									<Button mt={3} w='150px' colorScheme='blue' onClick={getAdminConfig}>Get</Button>
									<Text mt={3}>Bot balance: {Number(adminConfig.autoProfitBotBalance).toFixed(2)} {token.symbol}</Text>
									<Text mt={3} >Current percent: {adminConfig.profitPercent}% {` = ${(adminConfig.autoProfitCurrentInvest * adminConfig.profitPercent / 100).toFixed(2)} ${token.symbol}`}</Text>

									<Flex mt={3} flexDir='row' alignItems='center' justifyContent='space-between'>
										<Menu>
											<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
												Parameters
											</MenuButton>
											<MenuList>
												{
													menuItems.map(menuItem => {
														if (menuItem !== 'expandParam')
															return (
																<MenuItem
																	key={menuItem}
																	onClick={() => {
																		console.log(`Select param: ${menuItem}`);
																		handleAdminConfigInput('type', menuItem);
																		handleAdminConfigInput('value', adminConfig[menuItem as keyof AdminConfigInterface])
																	}}
																>
																	{menuItem}
																</MenuItem>
															)
														else {
															return (<></>)
														}
													})
												}
											</MenuList>
										</Menu>
									</Flex>
									<HStack w='full' spacing={3} mt={3}>
										<Text flex='3'>{adminConfigInput.type === '' ? 'Select params' : `Set ${adminConfigInput.type}`}</Text>
										<Input
											textAlign='right'
											flex='2'
											disabled={adminConfigInput.type === ''}
											type='number'
											value={adminConfigInput.value}
											onChange={e => { handleAdminConfigInput('value', e.target.value) }}
											placeholder='0'
										/>
										<Button
											flex='1'
											colorScheme={adminConfigInput.value <= 0 ? 'gray' : 'red'}
											disabled={adminConfigInput.value <= 0}
											onClick={() => { console.log(`start config: ${adminConfigInput.type}: ${adminConfigInput.value}`); updateAdminConfig() }}
										>
											Set
										</Button>
									</HStack>
									{
										adminConfigInput.type === 'profitPercent' &&
										<Text mt={3} >{adminConfigInput.value}% {` = ${(adminConfig.autoProfitCurrentInvest * adminConfigInput.value / 100).toFixed(2)} ${token.symbol}`}</Text>
									}
									{/* <>
										<HStack mt={3} w='full' spacing={3}>
											<Text flex='3'>Set percent </Text>
											<Input
												textAlign='right'
												flex='2'
												type='number'
												value={adminConfigInput.value}
												onChange={e => { handleAdminConfigInput('value', e.target.value) }}
												placeholder='0'
											/>
											<Button
												flex='1'
												colorScheme={adminConfigInput.value <= 0 ? 'gray' : 'red'}
												disabled={adminConfigInput.value <= 0}
												onClick={() => {
													updateAdminConfig();
												}}
											>
												Set
											</Button>
										</HStack>
										<Text mt={3} >{adminConfigInput.value}% {` = ${(adminConfig.autoProfitCurrentInvest * adminConfigInput.value / 100).toFixed(2)} ${token.symbol}`}</Text>
									</> */}



									<VStack w='full' h='auto' align='left' mt={3}>
										<Text as='b'>Profit Distribution</Text>
										<Button w='150px' colorScheme='blue' onClick={getAdminDistribution}>Get</Button>
										<Text>Send: {adminDistribution[0]}</Text>
										<Text>Amount: {adminDistribution[1]}</Text>
										<Text>Counter: {adminDistribution[2]}</Text>
									</VStack>
									<VStack w='full' h='auto' align='left' mt={3}>
										<div><b>Transfer Profit</b></div>
										<Text>Your wallet balance: {parseFloat(ethers.utils.formatUnits(userInfo.balance, token.decimal)).toFixed(2)} {token.symbol}</Text>
										<HStack w='full' align='center' spacing='5'>
											<Text flex='3'>Transfer profit</Text>
											<Input
												textAlign='right'
												flex='2'
												w='50%'
												isInvalid={Number(profitUsd) < 0}
												errorBorderColor='red.300'
												type='number'
												name='profit'
												value={profitUsd}
												onChange={(e) => setProfitUsd(e.target.value)}
												placeholder='0'
											/>
											<Button disabled={Number(profitUsd) <= 0} flex='1' w='200px' colorScheme={Number(profitUsd) <= 0 ? 'gray' : 'red'} onClick={processProfit}>Send</Button>
										</HStack>
									</VStack>


									<VStack w='full' h='auto' align='left' mt={3}>
										<div><b>Child Managers</b></div>
										{
											childManagers.map((childManager, i) => {
												return (
													<>
													<Divider mt={5} mb={5} />
													<VStack w='full' align='left' spacing='3' mt={5}>
														<Text w='auto'>{childManager.botName} - {isDesktop ? childManager.id : truncateAddress(childManager.id)} - {childManager.outTokenSymbol}  {childManager.childManager && (<> [{childManager.totalChilds} wallets]</>)}  </Text>
														{
															childManager.childManager ? (
																<>
																<Flex w='full' mt={3} flexDir={isDesktop ? 'row':'column'} alignItems='center' justifyContent='space-between'>
																	<Flex w='auto' mt='3'>
																		<Menu>
																			<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
																				{
																					childManagerConfigInput.param === '' || childManager.botName !== childManagerConfigInput.botName ? 
																					'Select Parameters' : `${childManagerConfigInput.param}`
																				}
																			</MenuButton>
																			<MenuList>
																				{
																					childManagerMenuItems.map((item) => {
																						if (item !== 'id'  && item !== 'totalChilds'  && item !== 'childManager' && item !== 'requestChildManager' && item !== 'botName' && item !== 'outTokenSymbol')
																							return (
																								<MenuItem
																									key={item}
																									onClick={() => {
																										// console.log(`Select param: ${item}`);
																										
																											handleChidManagerConfigInput('botName', childManager.botName);
																											handleChidManagerConfigInput('param', item);
																											handleChidManagerConfigInput('value', childManager[item as keyof ChildManagerInterface])
																										
																									}}
																								>
																									{item}: {childManager[item as keyof ChildManagerInterface]}
																								</MenuItem>
																							)
																						else {
																							return (<></>)
																						}
																					})
																				}
																			</MenuList>
																		</Menu>
																	</Flex>
																	<Input
																		ml='3'
																		mt='3'
																		w='full'
																		textAlign='right'
																		disabled={childManagerConfigInput.param === ''}
																		type='text'
																		value={childManager.botName === childManagerConfigInput.botName ? childManagerConfigInput.value : 0}
																		onChange={e => { handleChidManagerConfigInput('value', e.target.value) }}
																		placeholder='0'
																	/>
																	<Button
																	 	ml='3'
																		mt='3'
																		w={isDesktop?'150px':'full'}
																		colorScheme={Number(childManagerConfigInput.value) <= 0 ? 'gray' : 'red'}
																		disabled={Number(childManagerConfigInput.value) <= 0 || childManager.botName !== childManagerConfigInput.botName}
																		onClick={() => { 
																			console.log(`start Bot child config: ${childManagerConfigInput.param}: ${childManagerConfigInput.value}`); 
																			updateChildManagerConfig() 
																		}}
																	>
																		Set
																	</Button>
																	<Text>{childManagerConfigInput.botName}</Text>
																</Flex>
																
																</>
															) : (
																
																<>
																<HStack mt='3'>
																	<Button size='sm' disabled={Number(profitUsd) <= 0} w='80px' colorScheme='green'
																		onClick={() => { approveChildManager(childManager.id) }}
																	>Approve</Button>
																	<Button size='sm' disabled={Number(profitUsd) <= 0}  w='80px' colorScheme='red' 
																		onClick={() => { rejectChildManager(childManager.id) }}
																	>Reject</Button>
																</HStack>
																</>
															)
														}
														

													</VStack>
													</>
												)
											})
										}
									</VStack>

								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				</Accordion>
			</Flex>
		</>
	)
}

export default AdminConfigSetConfig