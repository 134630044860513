import { Constants, floatToken, getApi, truncateAddress, UserInfo } from '../utils'
import {
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	CloseButton,
	Modal,
	ModalOverlay,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	Button,
  useToast
} from '@chakra-ui/react'
import { useEthersContext } from '../context'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyIcon } from '@chakra-ui/icons'

export async function searchUserData(address: string, accessToken: string) {
  
  // console.log(`search _res ${JSON.stringify(_res)}`)

  var _user: any = {
    id: 'string',
    referrer: '0',
    currentPnL: '0',
    totalPnL: '0',
    currentInvest: '0',
    currentCommission: '0',
    totalCommission: '0',
    withdrawInvestAmount: '0',
    isPendingWithdrawal: false,
    isSuspended: false,
    isUser: false,
    approve: false,
    balance: '0',
  }
  try {
    const _res =  await getApi('/userInfos', {user: address}, accessToken)
    if (_res.length)
      Object.keys(_user).map((key, index) => _user[key] = _res[index])
  } catch (error) {
    console.log("🚀 ~ searchUserData ~ error:", error)
    
  }
  // console.log(`search user ${JSON.stringify(_user)}`)
  return _user
}

export function SearchUserModal({user, isOpen, onClose}: {user: UserInfo, isOpen: boolean, onClose: ()=>void}) {
  const { isDesktop, token,  isAdminAccess} = useEthersContext()

  const toast = useToast()
  async function copyAddress(text: string | null, result: boolean) {
    toast.closeAll()
    if (result) {
      toast({
        title: `Copy Successful`,
        description: `Account ${truncateAddress(text)} copied to clipboard`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title: `Can't copy`,
        description: `Account ${truncateAddress(text)} copy failed`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  
  return (
    <>
    {
      isAdminAccess && 
      <Modal isOpen={isOpen} onClose={onClose}>
        <CloseButton/>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size={isDesktop? 'md' : 'sm'}>
              <Tbody>
                <Tr>
                  <Th>Parameters</Th>
                  <Th>Value</Th>
                </Tr>
                {
                  (Object.keys(user).length > 0) && Object.keys(user).map((key: string, index: number) => {
                    var data: any;
                    var value = (user as any)[key];
                    if (index === 0 || index === 1){
                      data = (
                      <>
                        <CopyToClipboard text={String(value)}
                          onCopy={(text, result) => copyAddress(text, result)}>
                          <Button variant='link' leftIcon={<CopyIcon />}></Button>
                        </CopyToClipboard>
                        <a href={Constants.explorerUrl + value}
                          target='_blank' rel="noreferrer"
                        >
                          <Button variant='link'>{truncateAddress(value)}</Button>
                        </a>
                      </>
                      )
                    } else if ((index >= 2 && index <= 7) || (index === (Object.keys(user).length - 1))){
                      data = `${floatToken((user as any)[key], token)} ${token.symbol}`
                    }
                    else {
                      data = (user as any)[key]? 'true': 'false'
                    }
                    return (
                      <Tr key={index}>
                        <Td>{key}</Td>
                        <Td isNumeric>{data}</Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    }
  </>
  )
}


