import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { initializeConnector } from '@web3-react/core'
// import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { Constants } from '../utils'
// import type { AddEthereumChainParameter } from '@web3-react/types'
const injected = new InjectedConnector({
  supportedChainIds: [97, 56 , 250, 1, 42161, 137, 43114, 10, 25, 100]
});

// const ETH: AddEthereumChainParameter['nativeCurrency'] = {
//   name: 'BNB',
//   symbol: 'BNB',
//   decimals: 18,
// }

// interface BasicChainInformation {
//   urls: string[]
//   name: string
// }

// interface ExtendedChainInformation extends BasicChainInformation {
//   nativeCurrency: AddEthereumChainParameter['nativeCurrency']
//   blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
// }

// type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }
// const CHAINS: ChainConfig = {
//   56: {
//     urls: [Constants.endpointMainnet],
//     name: 'BSC Mainnet',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://mainnet.bscscan.com'],
//   },
//   97: {
//     urls: [Constants.endpointMainnet],
//     name: 'BSC Testnet',
//     nativeCurrency: ETH,
//     blockExplorerUrls: ['https://testnet.bscscan.com'],
//   },
// }

// const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number)

// export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
//   (actions) =>
//     new WalletConnectV2({
//       actions,
//       options: {
//         projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!,
//         chains: [mainnet],
//         optionalChains,
//         showQrModal: true,
//       },
//     })
// )

const walletconnect = new WalletConnectConnector({
  rpc: {
    97: Constants.endpointTestnet,
    56: Constants.endpointMainnet
  },
  chainId:Constants.networkChainId,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  supportedChainIds: [97, 56 , 250, 1, 42161, 137, 43114, 10, 25, 100]
});

const walletlink = new WalletLinkConnector({ 
  url: Constants.endpointMainnet,
  appName: "BunnyBot",
  supportedChainIds: [97, 56 , 250, 1, 42161, 137, 43114, 10, 25, 100]
});

const Connectors: any = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};

export default Connectors
