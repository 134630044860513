import React, { useState } from 'react'
import { getApi, ReferralList} from '../utils'
import {
	VStack,
	Box,
	Text,
	useDisclosure,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Spinner,
	// Table,
	// Tbody,
	// Tr,
	// Th,
	// Td,

} from '@chakra-ui/react'
import { AiOutlineTransaction } from 'react-icons/ai'
// import { FiUser } from "react-icons/fi";
import { Table as TableHistory } from "react-chakra-pagination";
import { useEthersContext } from '../context';
import { BsCashCoin } from 'react-icons/bs';

const UserHistory = () => {
	const { account, token, isDesktop, accessToken } = useEthersContext()
	const [ userHistory, setUserHistory ] = useState<ReferralList[]>([] as ReferralList[])
	const [ page, setPage ] = React.useState(1);
	const { isOpen: loading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure()

	const getUserHistory =  async() => {
		onOpenLoading()
		try {
			const refs = await getApi('/getUserHistory', { user: account }, accessToken)
			setUserHistory(refs)
		} catch (error) {
			console.log("🚀 ~ getUserHistory ~ error:", error)
			
		}
		onCloseLoading()
	}

	const tableData = userHistory.map((activity) => ({
		action: activity.action,
		timestamp: activity.timestamp,
		amount: (Number(activity.amount) / 10 ** token.decimal).toFixed(2) +'$',
	}))
	const tableColumns = [
		{
			Header: "Action",
			accessor: "action"
		},
		{
			Header: "Time",
			accessor: "timestamp"
		},
		{
			Header: "Amount",
			accessor: "amount"
		},
		
	]
	return (
		<>
			<Accordion allowToggle width={isDesktop?'full':'100%'} onChange={getUserHistory} bg='white' h='auto'>
				<AccordionItem>
					{({ isExpanded }) => (
					<>
						{
							isExpanded && loading && <Spinner 
								style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='blue.500'
								size='xl' 
							/>
						}
						<h2>
						<AccordionButton>
							<Box as="b" flex='1' textAlign='left' >
								<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
									<BsCashCoin size={20}/><Text ml={2}>Activity Logs</Text>
								</div>	
							</Box>
							<AccordionIcon />
						</AccordionButton>
						</h2>
					<AccordionPanel >
						<Box w='full' h='auto' bg='white'  >
							<VStack w='full' h='full' align='left'>
								<TableHistory
									colorScheme="blue"
									// Fallback component when list is empty
									emptyData={{
										icon: AiOutlineTransaction,
										text: "No Transaction"
									}}
									totalRegisters={userHistory.length}
									page={page}
									// Listen change page event and control the current page using state
									onPageChange={(page) => setPage(page)}
									columns={tableColumns}
									data={tableData}
								/>
								{/* <Table w='full' h='auto'>
									<Tbody>
									<Tr>
										<Th>Action</Th>
										<Th>Time</Th>
										<Th isNumeric>Amount</Th>
									</Tr>
									{
										userHistory.map((activity, i) => {
											return (
												<Tr key={i}>
													<Td>{activity.action}</Td>
													<Td>{activity.timestamp}</Td>
													<Td isNumeric>{(Number(activity.amount) / 10 ** token.decimal).toFixed(2) +'$'}</Td>
												</Tr>
											)
										})
									}
									</Tbody>
								</Table> */}
							</VStack>
						</Box>
					
					</AccordionPanel>
					</>
    				)}
				</AccordionItem>
			</Accordion>
			
		
		</>
	)
}

export default UserHistory