import React, {
    useContext,
    createContext,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import {
    Constants,
    getApi,
    UserInfo,
    Token,
    Config,
    useViewPort,
    ENUM_USER,
    TokenOwnerConfigInterface,
    TokenOwnerUserInterface,
    TokenSaleIBConfig,
    NetworkUserStruct,
} from "../utils";
import { Connectors } from "../components";
import { useToast } from "@chakra-ui/react";
import { decrypt } from "n-krypta";

interface TokenOwnerInterface {
    user: TokenOwnerUserInterface;
    config: TokenOwnerConfigInterface;
    ibConfig: TokenSaleIBConfig;
}

interface ContractsInterface {
    botContract: ethers.Contract;
    configContract: ethers.Contract;
    networkContract: ethers.Contract;
    syncContract: ethers.Contract;
    userContract: ethers.Contract;
    nodeContract: ethers.Contract;
    nodeStatisticContract: ethers.Contract;
    childContract: ethers.Contract;
}

interface EtherContext {
    userInfo: UserInfo;
    networkUserInfo: NetworkUserStruct;
    contracts: ContractsInterface | null;
    token: Token;
    validChain: boolean;
    activeWallet: boolean;
    account: string | null | undefined;
    disconnect: () => void;
    loadingUser: boolean;
    validUser: boolean;
    config: Config;
    approvingContract: boolean;
    handleApproveContract: () => void;
    isDesktop: boolean;
    isAdminAccess: boolean;
    isManagerAccess: boolean;
    accessToken: string;
    isBdAccess: boolean;
    tokenSale: {
        data: TokenOwnerInterface;
        getTokenSaleConfig: () => void;
        getTokenSaleUser: () => void;
    };
    contractAddress: string;
    root: string
}

const ethersContext = createContext({} as EtherContext);

const DEFAULT_USER_NETWORK_INFO: NetworkUserStruct = {
    id: "",
	currentLockIncome: "0",
	totalNetworkIncome: "0",
	currentNetworkIncome: "0",
	totalLeftNodeChilds: "0",
	totalRightNodeChilds: "0",
	totalNodeChilds: "0",
	currentLeftNodeIncome: "0",
	currentRightNodeIncome: "0",
	currentTotalNodeIncome: "0",
	totalBlacklist: "0",
	currentNetworkRevenue: "0",
	isWarning: false,
	isBlacklist: false,
	isRejected: false,
}

export const EthersContextProvider = ({
    children,
}: React.PropsWithChildren) => {
    const { chainId, library, active, account, activate, deactivate } =
        useWeb3React();
    const [userInfo, setUserInfo] = useState<UserInfo>({
        id: "string",
        referrer: "0",
        currentPnL: "0",
        totalPnL: "0",
        currentInvest: "0",
        currentCommission: "0",
        totalCommission: "0",
        withdrawInvestAmount: "0",
        isPendingWithdrawal: false,
        isSuspended: false,
        isUser: false,
        approve: false, //Keep this variable at this position
        balance: "0", //Keep this variable at this position
        isNetworkUser: false, //V3 network
        username: "-",
        isWaitingForNetworkApprove: false,
        networkRegisterTimestamp: "0",
    } as UserInfo);
    const [networkUserInfo, setNetworkUserInfo] = useState<NetworkUserStruct>(DEFAULT_USER_NETWORK_INFO);
    const [validChain, setValidChain] = useState<boolean>(false);
    const [token, setToken] = useState<Token>({
        address: ethers.constants.AddressZero,
        symbol: "",
        decimal: 0,
    } as Token);
    const [activeWallet, setActiveWallet] = useState<boolean>(false);
    const request = useRef<boolean>(false);
    const [validUser, setValidUser] = useState<boolean>(false);
    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [approveContract, setApproveContract] = useState<boolean>(false);
    const [config, setConfig] = useState<Config>({
        userWithdrawFee: "0",
        referralFee: "0",
        platformFee: "0",
        minimumInvest: "0",
        remainReserve: "0",
        minReserve: "0",
        normalReserve: "0",
        childTokenBalanceThreshold: '0', //minimum child balance to distribute
        childWETHBalanceThreshold: '0'
    } as Config);
    const [approvingContract, setApprovingContract] = useState<boolean>(false);
    const toast = useToast();
    const viewPort = useViewPort();
    const isDesktop =
        viewPort.width > Number(Constants.mobileWidth) ? true : false;
    const firstLoadUser = useRef<boolean>(false);
    const [isAdminAccess, setIsAdminAccess] = useState<boolean>(false);
    const [isManagerAccess, setIsManagerAccess] = useState<boolean>(false);
    const [isBdAccess, setIsBdAccess] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>("");
    const [contractAddress, setContractAddress] = useState<string>("");
    const [contracts, setContracts] = useState<ContractsInterface | null>(null);
    const [root, setRoot] = useState<string>("" as string)

    const [tokenSale, setTokenSale] = useState<TokenOwnerInterface>({
        user: {
            isUser: false,
            id: ethers.constants.AddressZero,
            balance: "0",
            history: [],
            totalUserBonus: "0",
            totalUSDBuy: "0",
            totalUSDBonus: "0",
            ibTotalClaimed: "0", //v2.2
            ibTotalClaimedUSD: "0", //v2.2
            isIB: false, //v2.2
            ibBonus: "0", //v2.2
            ibBonusUSD: "0", //v2.2
            ibIsIIB: false, //v2.3
            ibIIBBonusUSD: "0", //v2.3
            ibIIBTotalClaimedUSD: "0", //v2.3
            ibPayoutRatio: "0", //v2.3
            approve: false,
        },
        config: {
            totalSellToken: "0",
            maxSellToken: "0",
            tokenPrice: "0",
            freezing: false,
            availableSellToken: "0",
            usdSellToken: "0",
            bonusRate: "0",
            totalBonus: "0",
            bonusRateUSD: "0",
            totalBonusUSD: "0",
            contractKeepRate: "0",
            presaleRound: "0",
            presaleTotal: "0",
            presaleStart: "0",
            presalePrice: "0",
            ibIIBUsers: [],
        },
        ibConfig: {
            ibTotalBonusCondition: "0",
            ibTotalUserCondition: "0",
            ibPurchasedPerUserCondition: "0",
            ibBonusPercentage: "0",
            ibTotalUserClaimed: "0",
            ibCurrentIBExtra: "0",
            ibCurrentIBExtraUSD: "0",
            ibTotalIBExtra: "0",
            ibCurrentIIBUSD: "0",
        },
    } as TokenOwnerInterface);

    const handleSetContracts = (key: string, value: ethers.Contract) => {
        setContracts(
            (prev) => ({ ...prev, [key]: value } as ContractsInterface)
        );
    };

    const handleSetTokenSale = (
        type: string,
        key: string,
        value: string | boolean
    ) => {
        if (type === "config")
            setTokenSale((prev) => ({
                ...prev,
                config: {
                    ...prev.config,
                    [key]: value,
                },
            }));
        else if (type === "user") {
            setTokenSale((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    [key]: value,
                },
            }));
        } else {
            setTokenSale((prev) => ({
                ...prev,
                ibConfig: {
                    ...prev.ibConfig,
                    [key]: value,
                },
            }));
        }
    };

    // const getContract = () => {
    // if (active){
    //   const signer = library.getSigner()
    //   // console.log(`[Getcontract: contract address ${contractAddress}]`)
    //   const contract = new ethers.Contract(Constants.contractAddress, Constants.contractAbi, signer)
    //   return contract
    // } else
    //   return undefined
    // }

    function disconnect(): void {
        console.log(`Disconnect`);
        window.localStorage.setItem("provider", "undefined");
        deactivate();
        setValidChain(false);
        setActiveWallet(false);
        setValidUser(false);
    }

    /*
    Check provider is connected
  */
    useEffect(() => {
        async function getTokenInfo(account: string | null | undefined) {
            console.log(`getTokenInfo`);
            try {
                const info = await getApi("/tokenInfo", { user: account });
                setAccessToken(info.token);
                setToken((token) => ({
                    ...token,
                    address: info.address,
                    decimal: info.decimal,
                    symbol: info.symbol,
                }));
            } catch (error) {
                console.log("🚀 ~ getTokenInfo ~ error:", error);
            }
        }

        const windowProvider = window.localStorage.getItem("provider");
        if (windowProvider !== null && !request.current) {
            try {
                activate(Connectors[windowProvider]);
                console.log(
                    `window provider: ${windowProvider}. ChainId: ${chainId}`
                );
            } catch (error) {
                console.log(error);
            }
        }
        if (active) {
            setActiveWallet(true);
            if (chainId === undefined || chainId !== Constants.networkChainId) {
                setValidChain(false);
                // console.log(`invalid chain ${chainId} - ${defaultNetwork}`)
            } else {
                getTokenInfo(account);
                setValidChain(true);
                setActiveWallet(true);
                console.log(
                    `valid chains: ${chainId} - ${Constants.networkChainId} - ${account}`
                );
            }
        }

        return () => {
            request.current = true;
        };
        // eslint-disable-next-line
    }, [chainId, active]);

    const handleUserInfoChange = (name: string, value: string | boolean) => {
        setUserInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleNetworkUserInfoChange = (
        name: string,
        value: string | boolean
    ) => {
        setNetworkUserInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSetConfig = (key: string, value: string) => {
        setConfig((config) => ({ ...config, [key]: value }));
    };

    /*
    Fetch user data from server
  */

    const fetchNetworkUserData = useCallback(async () => {
        console.log("Context: fetchNetworkUserData");
        if (accessToken === "") {
            // console.log(`Context: fetchNetworkUserData access token is empty`)
            return;
        }
        if (!userInfo.isNetworkUser && !userInfo.isWaitingForNetworkApprove) {
            // console.log(`Context: fetchNetworkUserData not network user or not waiting for network approve`)
            setNetworkUserInfo(DEFAULT_USER_NETWORK_INFO);
            return;
        }
        try {
            //Get userInfo from the contract
            const _networkUserInfo = await getApi(
                "/getNodeInformation",
                { user: account },
                accessToken
            );
            // console.log(`Context: fetchNetworkUserData ${JSON.stringify(_networkUserInfo, null, 2)}`)
            if (Object.keys(_networkUserInfo).length) {
                // Object.keys(networkUserInfo).map((key, index) =>
                //     handleNetworkUserInfoChange(key, _networkUserInfo[index])
                // );
                setNetworkUserInfo(_networkUserInfo)
            } else {
                setNetworkUserInfo(DEFAULT_USER_NETWORK_INFO);
            }
        } catch (e) {
            console.log(`Fetchdata error: ${e}`);
        }
        // eslint-disable-next-line
    }, [account, validUser, approveContract, accessToken, userInfo.isWaitingForNetworkApprove, userInfo.isNetworkUser]);

    const fetchData = useCallback(async () => {
        console.log("Context: fetchData");
        if (accessToken === "") {
            return;
        }

        try {
            //Get userInfo from the contract
            var _userInfo: any = [];
            // var _networkUserInfo: any = [];
            var _isApprove: boolean = false;
            _userInfo = await getApi(
                "/userInfos",
                { user: account },
                accessToken
            );
            Object.keys(userInfo).map((key, index) =>
                handleUserInfoChange(key, _userInfo[index])
            );
            _isApprove = _userInfo[ENUM_USER.approve];
			// console.log(`[API] isNetworkUser ${JSON.stringify(_userInfo, null, 2)}`)
            // if (_userInfo[ENUM_USER.isWaitingForNetworkApprove]  || _userInfo[ENUM_USER.isNetworkUser]) {
            //     _networkUserInfo = await getApi(
            //         "/networkUserInfos",
            //         { user: account },
            //         accessToken
            //     );
			// 	// console.log(`_networkUserInfo: ${JSON.stringify(_networkUserInfo.length)}`)
            //     if (_networkUserInfo.length) {
			// 		Object.keys(networkUserInfo).map((key, index) =>
			// 			handleNetworkUserInfoChange(key, _networkUserInfo[index])
			// 		);
			// 	}
            // } else {
            //     setNetworkUserInfo(DEFAULT_USER_NETWORK_INFO);
            // }

            if (_userInfo[ENUM_USER.isUser]) {
                // console.log(JSON.stringify(userInfo))
                if (_userInfo[ENUM_USER.isSuspended]) {
                    if (validUser) {
                        setValidUser(false);
                        setApproveContract(false);
                    }
                } else if (!validUser) {
                    setValidUser(true);
                    console.log(`Account is user`);
                    // console.log(JSON.stringify(userInfo))
                }
                if (firstLoadUser.current) {
                    setLoadingUser(false);
                }
            } else {
                // console.log(`not valid user`)
                if (validUser) {
                    setValidUser(false);
                    setApproveContract(false);
                    console.log(`Account changed. Not user`);
                }
                setLoadingUser(false);
                return;
            }

            if (_isApprove) {
                if (!approveContract) {
                    console.log(`Set approve`);
                    setApproveContract(true);
                    setApprovingContract(false);
                }
            } else {
                // console.log(`not approve`)
                if (approveContract) {
                    setApproveContract(false);
                }
            }
        } catch (e) {
            console.log(`Fetchdata error: ${e}`);
        }
        // eslint-disable-next-line
    }, [account, validUser, approveContract, accessToken]);

    /*
    Load config from server
  */

    const getConfig = useCallback(async () => {
        console.log(`Context: getConfig`);
        try {
            if (active && contractAddress !== "") {
                const signer = library.getSigner();

                Object.keys(Constants.contractAbi).forEach((key) => {
                    console.log(`[getConfig]: ${key}`);
                    const contract = new ethers.Contract(
                        contractAddress,
                        Constants.contractAbi[
                            key as keyof typeof Constants.contractAbi
                        ],
                        signer
                    );
                    handleSetContracts(key, contract);
                });
                console.log(
                    `[getConfig] setContracts: contract address ${contractAddress}`
                );
            }
        } catch (e) {
            console.log(`[getConfig] setContracts: ${e}`);
        }
        try {
            if (accessToken) {
                const _config = await getApi(
                    "/botConfig",
                    { user: account },
                    accessToken
                );
                const _manager = decrypt(
                    _config.m,
                    Constants.encryptAPIPassword! + account
                );
                const _onwer = decrypt(
                    _config.o,
                    Constants.encryptAPIPassword! + account
                );
                const _bd = decrypt(
                    _config.b,
                    Constants.encryptAPIPassword! + account
                );
                const _root = decrypt(
                    _config.rt,
                    Constants.encryptAPIPassword! + account
                );
                const _isOwner = _onwer.includes(account);
                const _isManager = _manager.includes(account);
                setIsManagerAccess(_isManager || _isOwner);
                setIsAdminAccess(_isOwner);
                setIsBdAccess(_bd.includes(account));
                setContractAddress(_config.c);
                setRoot(_root)
                // console.log(`[getConfig] _manager ${_manager} ${_manager.includes(account)}`)
                Object.keys(config).map((key, index) =>
                    handleSetConfig(key, _config.data[index])
                );
            }
        } catch (e) {
            console.log("🚀 ~ getConfig ~ e:", e);
        }
        // eslint-disable-next-line
    }, [account, accessToken, contractAddress]);

    const getTokenSaleConfig = useCallback(async () => {
        console.log(`Context: getTokenSaleConfig`);
        try {
            if (!accessToken) {
                return;
            }
            let res = await getApi("/token/getTokenConfig", {}, accessToken);
            // console.log(`🚀 ~ getTokenSaleConfig ~ res:`, JSON.stringify(res,null,2))
            if (res.length) {
                Object.keys(tokenSale.config).map((key, i) =>
                    handleSetTokenSale("config", key, res[i])
                );
            }
            res = await getApi("/token/getTokenIBConfig", {}, accessToken);
            if (res.length) {
                Object.keys(tokenSale.ibConfig).map((key, i) =>
                    handleSetTokenSale("ibConfig", key, res[i])
                );
            }
        } catch (error) {
            console.log(
                `🚀 ~ file: TokenBFi.tsx:111 ~ getTokenSaleConfig ~ error: ${error}`
            );
        }
        // eslint-disable-next-line
    }, [account, accessToken]);

    const getTokenSaleUser = useCallback(async () => {
        try {
            if (!accessToken) {
                return;
            }
            console.log(`Context: getTokenSaleUser`);
            const res = await getApi(
                "/token/getTokenUser",
                { user: account },
                accessToken
            );
            // console.log(`🚀 ~ getTokenSaleUser ~ res:`, JSON.stringify(res,null,2))
            if (res.length) {
                Object.keys(tokenSale.user).map((key, i) =>
                    handleSetTokenSale("user", key, res[i])
                );
            }
        } catch (e) {
            console.log(e);
        }
        // eslint-disable-next-line
    }, [account, accessToken]);

    /*
    Check if user is valid, and load data from server
  */
    useEffect(() => {
        if (!active) return;
        console.log("useEffect: FetchData FetchConfig");
        getConfig();
        fetchData();
        setLoadingUser(true);
        getTokenSaleConfig();
        getTokenSaleUser();
        const timeout1 =  setTimeout(() => {
            fetchNetworkUserData();
        }, 1500);
        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        const interval1 = setInterval(() => {
            getTokenSaleUser();
        }, 15000);
        const interval2 = setInterval(() => {
            getConfig();
            getTokenSaleConfig();
        }, 300000);

        const interval3 = setInterval(() => {
            fetchNetworkUserData();
        }, 8000);

        return () => {
            clearInterval(interval);
            clearInterval(interval1);
            clearInterval(interval2);
            clearInterval(interval3);
            clearTimeout(timeout1);
            firstLoadUser.current = true;
        };
    }, [
        fetchData,
        active,
        account,
        accessToken,
        getConfig,
        getTokenSaleConfig,
        getTokenSaleUser,
        fetchNetworkUserData,
    ]);

    async function handleApproveContract() {
        toast({
            title: `Contract Approval`,
            description: `Approving BunnyBot`,
            status: "loading",
            duration: 3000,
            isClosable: true,
        });
        setApprovingContract(true);
        const signer = library.getSigner();
        const usdContract = new ethers.Contract(
            Constants.tokenAddress as string,
            Constants.erc20Abi,
            signer
        );
        try {
            const tx = await usdContract.approve(
                contractAddress,
                ethers.constants.MaxUint256,
                {
                    gasPrice: await library.getGasPrice(),
                }
            );
            // console.log(`Approve hash: ${tx.hash}`)
            const receipt = await library.waitForTransaction(tx.hash, 1);
            toast.closeAll();
            if (receipt.status) {
                console.log(`Approval done`);
                toast({
                    title: `Contract Approval`,
                    description: `Contract is approved successfully`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.log(`Approval fail`);
                toast({
                    title: `Contract Approval`,
                    description: `Approve contract failed`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (e: any) {
            let _e = "";
            if (e.reason !== undefined) {
                _e = e.reason;
            } else if (e.data.message !== undefined) {
                _e = e.data.message + ". Send some BNB to your wallet";
            }
            console.log(`_e: ${JSON.stringify(_e)}`);
            setApprovingContract(false);
            console.log(`Approval error: ${JSON.stringify(e)}`);
            toast({
                title: `Contract Approval`,
                description: `Approve contract failed. ${_e}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    return (
        <ethersContext.Provider
            value={{
                userInfo,
                networkUserInfo,
                contracts: contracts,
                token: token,
                validChain: validChain,
                activeWallet: activeWallet,
                account: account,
                disconnect: disconnect,
                loadingUser: loadingUser,
                validUser: validUser,
                config: config,
                approvingContract: approvingContract,
                handleApproveContract: handleApproveContract,
                isDesktop: isDesktop,
                isAdminAccess: isAdminAccess,
                isManagerAccess: isManagerAccess,
                accessToken: accessToken,
                isBdAccess: isBdAccess,
                tokenSale: {
                    data: tokenSale,
                    getTokenSaleConfig: getTokenSaleConfig,
                    getTokenSaleUser: getTokenSaleUser,
                },
                contractAddress: contractAddress,
                root: root
            }}
        >
            {children}
        </ethersContext.Provider>
    );
};

export const useEthersContext = () => useContext(ethersContext);
