import {
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Constants, truncateAddress } from '../utils'
import { logo } from '../assets'
import './styles.css'
import { useEthersContext } from '../context'
import { useNavigate } from 'react-router-dom'
import { RiAdminFill, RiHomeHeartFill } from 'react-icons/ri'
import { IoIosGitNetwork } from 'react-icons/io'
import { FaExchangeAlt } from 'react-icons/fa'
import { BiUser } from 'react-icons/bi'
import { BsCoin } from 'react-icons/bs'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { AiOutlineMenuFold } from 'react-icons/ai'
// import { MdMenuOpen } from 'react-icons/md'
// import { TfiMenuAlt } from 'react-icons/tfi'
// import { CgMenuGridR } from 'react-icons/cg'
// import { GiHamburgerMenu } from 'react-icons/gi'
// import { IconContext } from "react-icons";
import Menu from './Menu'
import { UnderConstructionModal } from './Modal'
import { userInfo } from 'os'

function Header() {
  const { activeWallet, account, disconnect, isDesktop, isManagerAccess, userInfo } = useEthersContext()
  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure()
  const { isOpen: isOpenPopup, onOpen: onOpenPopup, onClose: onClosePopup } = useDisclosure()

  const navigate = useNavigate()
  const space = isDesktop ? '5' : '2'
  const btnHeight = '37px'
  const btnWidth = '130px'
  return (
    <>
      <Flex w='full' h='60px' alignItems='center' justifyContent='space-around' color='white' bg='#2b2d3c'>
        <Flex w='full' flex='2' h='auto' justifyContent='left' ml={space} alignItems='center'>
          <a href={Constants.websiteUrl} target='_blank' rel="noreferrer"><Image w='117px' h='45px' src={logo} alt='logo'/></a>
        </Flex>
          
        <Flex w='full' flex={isDesktop ? '4' : '3'} justifyContent='flex-end' alignItems='center'>
        {
          activeWallet && (
            <> 
              {
                //Display truncate account address on desktop view
                isDesktop && (
                 <>
                  <Button leftIcon={<RiHomeHeartFill color='white' />} variant='ghost' h='30px' color='gray.500'  onClick={()=> { navigate('/user') }}  ml='10'>
                    Home
                  </Button>
                  <Center height='30px'>
                    <Divider orientation='vertical' />
                  </Center>
                  <Button leftIcon={<IoIosGitNetwork color='white' />} variant='ghost' h='30px' color='gray.500'  onClick={()=>{navigate('/network')}} >
                    Network
                  </Button>
                  <Center height='30px'>
                    <Divider orientation='vertical' />
                  </Center>
                  <Button leftIcon={<FaExchangeAlt color='white' />} variant='ghost' h='30px' color='gray.500'  onClick={onOpenPopup} >
                    Exchange
                  </Button>
                  <Center height='30px'>
                    <Divider orientation='vertical' />
                  </Center>
                  <Button leftIcon={<BsCoin color='white' />} variant='ghost' h='30px' color='gray.500'  onClick={()=> { navigate('token') }}  >
                    Token
                  </Button>
                  {
                    //Admin button is available only on desktop view
                    isManagerAccess && (
                      <>
                      <Center height='30px'>
                        <Divider orientation='vertical' />
                      </Center>
                      <Button leftIcon={<RiAdminFill color='white' />} variant='ghost' h='30px' color='gray.500' onClick={()=> { navigate('admin') }} >
                        Admin
                      </Button>
                      </>
                    )
                  } 
                  <Center height='30px' mr='3'>
                    <Divider orientation='vertical' />
                  </Center>

                  <BiUser/> 
                  <Text w='200px' ml='3' color='gray.500'> {truncateAddress(account)}</Text>
                 </>
                )
              }
              <Button size='sm' width={btnWidth} height={btnHeight} mr={space} color='white' bg='gray.600' onClick={disconnect} rightIcon={<AiOutlineDisconnect/>}>
                Disconnect
              </Button>
            </>
          ) 
        }
        </Flex>  
        { 
          !isDesktop && 
          <Flex w='full' flex='1' h='auto' justifyContent='center' mr={space} alignItems='center'>
            <Button bg='#27272C' color='white' onClick={onOpenMenu}><AiOutlineMenuFold size={23}/></Button>
          </Flex>
        }
        
      </Flex>
      
      <Menu isOpenMenu={isOpenMenu} onCloseMenu={onCloseMenu}  />
      <UnderConstructionModal isOpen={isOpenPopup} onClose={onClosePopup}/>
    </>
  )
}

export default Header