import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import {
	Button,
	Text,
	VStack,
	Table,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  HStack,
  Flex,
  Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
	Input,
  Spinner
} from '@chakra-ui/react'

import {ChevronDownIcon} from '@chakra-ui/icons'
import { useState } from 'react'
import { getApi, selectBorder, selectPadding, Config } from '../utils'
import { useEthersContext } from '../context'
export default function BotConfig() {
  const { contracts, token, isDesktop, account, accessToken } = useEthersContext()
  const { library } = useWeb3React()
	const toast = useToast()
	const [botConfigInput, setBotConfigInput ] = useState({
    type: '',
    value: 0,
  })
  const [ loading, setLoading ] = useState<boolean>(false)
  const menuItems = [
    'Withdraw Fee (%)', 
    'Referral Fee (%)', 
    'Platform Fee (%)', 
    'Minimum Invest (USD)', 
    'Min Reserve (USD)', 
    'Normal Reserve (USD)',
    'childTokenBalanceThreshold (USD)', 
    'childWETHBalanceThreshold (ETH)'
  ]

	const [ botConfig, setBotConfig] = useState<Config>({
    userWithdrawFee: '0',
    referralFee: '0',
    platformFee: '0', 
    minimumInvest: '0',
    remainReserve: '0',
    minReserve: '0',
    normalReserve: '0',
    childTokenBalanceThreshold: '0', //minimum child balance to distribute
    childWETHBalanceThreshold: '0'
  } as Config)

  function hanleSetBotConfigInput (name: string, value: number | string) {
    setBotConfigInput(botConfigInput => ({
     ...botConfigInput,
      [name]: value,
    }))
  }

  function handleBotConfig(name:string, value: string) {
    setBotConfig(prev => ({ ...prev, [name]: value }))
  }

  async function getBotConfig() {
    setLoading(true)
    try {
      console.log(`getBotConfig`)
      const res = await getApi('/botConfig', { user: account}, accessToken)
      // console.log(`_keys ${JSON.stringify(res)}`)
  
      Object.keys(botConfig).map( (key, index) => handleBotConfig(key, res.data[index]))
    } catch (error) {
      console.log("getBotConfig ~ error:", error)
      
    }
    setLoading(false)
	}
  
  async function processSetBotConfig() {
    const type = botConfigInput.type;
    const value = botConfigInput.value;
		console.log(`Process botConfig ${type}: ${value}`)
		try {
			var tx
      switch (type) {
        case menuItems[0]:
          tx = await contracts?.configContract.bnwkSetUserWithdrawFee(value*10, {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[1]:
          tx = await contracts?.configContract.bnwkSetReferralFee(value*10, {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[2]:
          tx = await contracts?.configContract.bnwkSetPlatformFee(value*10, {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[3]:
          tx = await contracts?.configContract.bnwkSetMinInvest(value, {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[4]:
          tx = await contracts?.configContract.bnwkSetMinReserve(ethers.utils.parseUnits(String(value), token.decimal), {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[5]:
          tx = await contracts?.configContract.bnwkSetNormalReserve(ethers.utils.parseUnits(String(value), token.decimal), {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[6]:
          tx = await contracts?.configContract.bnwkSetChildTokenBalanceThreshold(ethers.utils.parseUnits(String(value), token.decimal), {
            gasPrice: await library.getGasPrice()
          })
          break;
        case menuItems[7]:
          tx = await contracts?.configContract.bnwkSetChildWETHBalanceThreshold(ethers.utils.parseUnits(String(value), 18), {
            gasPrice: await library.getGasPrice()
          })
          break;
        default:
          break;
      }
			
			console.log(`Set botConfig ${type} hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Set botConfig ${type}: ${value} success`)
				toast.closeAll()
				toast({
					title: `Set botConfig ${type}`,
					description: `Set botConfig ${type}: ${value}`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
        getBotConfig()
        hanleSetBotConfigInput('value', 0)
        hanleSetBotConfigInput('type', '')

			} else {
				console.log(`Set botConfig ${type} failed`)
			}
		} catch (e: any) {
      toast.closeAll()
      toast({
        title: `Set botConfig ${type}`,
        description: `Set botConfig ${type}: fail`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
			if (e.error) {
        console.log(`Set botConfig ${type} failed: ${JSON.stringify(e.error)}`)
      } else {
        console.log(e)
      }
		}
	}

  return (
    <Box w='full' h='full' bg='white' justifyContent='left' borderRadius={selectBorder(isDesktop)} p={selectPadding(isDesktop)}>
      <Accordion allowToggle width='full' onChange={getBotConfig} bg='white' pt={2} pb={2} >
        <AccordionItem>
          {({ isExpanded }) => (
          <>
            {
              isExpanded && loading && 
                <Spinner 
                  style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl' 
                /> 
            }
            <h2>
            <AccordionButton>
              <Box as="b" flex='1' textAlign='left' >
                Bot Config
              </Box>
              <AccordionIcon />
            </AccordionButton>
            </h2>
          <AccordionPanel >
            <VStack w='full' align='left'>
              <Button w='150px' colorScheme='blue' onClick={getBotConfig}>Get</Button>
              <Table w='full' h='auto'>
                <Tbody>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Value</Th>
                  </Tr>
                  {
                    (Object.keys(botConfig).length > 0) && Object.keys(botConfig).map((key: string, i) => {
                      var value
                      if (i < 3)
                        value = `${Number(botConfig[key as keyof typeof botConfig])/10}%`
                      else 
                        value = `${parseFloat(ethers.utils.formatUnits(botConfig[key as keyof typeof botConfig], token.decimal)).toFixed(2)}$`


                      return (
                        <Tr key={i}>
                          <Td>{key}</Td>
                          <Td>{value}</Td>
                        </Tr>
                      )
                    })
                  }
                </Tbody>
              </Table>
              <Flex w='full' justifyContent='flex-start'>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Select Config
                  </MenuButton>
                  <MenuList>
                  { 
                    menuItems.map(menuItem => {
                      return (
                        <MenuItem 
                          key={menuItem} 
                          onClick={() => { console.log(`Select config: ${menuItem}`); hanleSetBotConfigInput('type', menuItem)}} 
                        >
                          {menuItem}
                        </MenuItem>
                      )
                    })
                  }
                  </MenuList>
                </Menu>
              </Flex>
              <Text>Config <b>{botConfigInput.type}</b></Text>
              <HStack w='full'>
                <Input
                  textAlign='right'
                  disabled={botConfigInput.type === ''}
                  type='number'
                  value={botConfigInput.value}
                  onChange={e => { hanleSetBotConfigInput('value', e.target.value)} }
                  placeholder='0'
                />
                <Button
                  colorScheme={botConfigInput.value <= 0 ? 'gray' : 'red'}
                  disabled={botConfigInput.value <= 0}
                  onClick={() => { console.log(`start config: ${botConfigInput.type}: ${botConfigInput.value}`); processSetBotConfig()} }
                >
                  Process
                </Button>
              </HStack>
            </VStack>
          
          </AccordionPanel>
          </>
            )}
        </AccordionItem>
      </Accordion>
      
    </Box>
    
  )
}
