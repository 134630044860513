import {
	Spinner,
} from "@chakra-ui/react";

function LoadingData({isOpen}: {isOpen: boolean}) {
  return (
    <>
      {
        isOpen && 
        <Spinner 
          style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl' 
        />
      }
    </>
  )
}

export default LoadingData