import React from 'react'
import { ethers } from 'ethers'
import {
	Button,
	Text,
	VStack,
	Table,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
  Spinner,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useEthersContext } from '../context'
import { getApi, floatToken, selectBorder, selectPadding, BotStatisticInterface } from '../utils'
import { useWeb3React } from '@web3-react/core'

function BotStatistic() {
  const { token, isDesktop, isAdminAccess, accessToken } = useEthersContext()
  const { chainId } = useWeb3React()
  const [botStatistic, setBotStatistic] = useState<BotStatisticInterface>({
    totalBotUser: '0',
    totalBotPnL: '0',
    currentBotPnL: '0',
    totalBotInvest: '0',
    currentBotInvest: '0',
    totalBotCommission: '0',
    currentBotCommission: '0',
    pendingWithdrawalAmount: '0',
    totalNetworkUsers: '0', //V3 network
    currentTokenPnL: '0',
    totalTokenPnL: '0',
    totalManagementFee: '0',
    maxNetworkDepth: '0',
    contractBalance: '0' //Keep at the end
  } as BotStatisticInterface)
  const [ expand, setExpand ] = useState<number>(0)
  const [ loading, setLoading ] = useState<boolean>(false)

  function handleBotStatistic(name:string, value: string) {
    setBotStatistic(prev => ({ ...prev, [name]: value }))
  }
  async function getBotStatistic() {
    setLoading(true)
		try {
      console.log(`getBotStatistic`)
      const res = await getApi('/getBotStatistic',{}, accessToken)
      const _res = await getApi('/admin/getConfig',{}, accessToken)
      if (chainId === 56)
        setExpand(_res[11]) //Expand param parameter in menuItems
      Object.keys(botStatistic).map( (key, index) => handleBotStatistic(key, res[index]))
    } catch (error) {
      console.log("🚀 ~ getBotStatistic ~ error:", error)
      
    }
    setLoading(false)
	}

  return (
    <Box w='full' h='auto' bg='white' justifyContent='left' borderRadius={selectBorder(isDesktop)} p={selectPadding(isDesktop)}>
      <Accordion allowToggle width='full' onChange={getBotStatistic} bg='white' pt={isAdminAccess ? 2: 0} pb={2} >
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              {
                isExpanded && loading && <Spinner 
                  style={{display:'block', position: "fixed", height:'50px', width:'50px', top: "50%", left: "50%" }}
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl' 
                />
              }
              <h2>
              <AccordionButton>
                <Box as="b" flex='1' textAlign='left' >
                  Bot Statistic
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
            <AccordionPanel >
              <VStack w='full' align='left'>
                <Button w='150px' colorScheme='blue' onClick={getBotStatistic}>Get</Button>
                {
                  (Object.keys(botStatistic).length > 0) && (
                    !isAdminAccess ? (
                      <>
                        <Text>Total User: {botStatistic.totalBotUser}</Text>
                        <Text>Total Investment: {floatToken(botStatistic.totalBotInvest, token)} {token.symbol}</Text>
                        <Text>Current Investment: {Number(floatToken(botStatistic.currentBotInvest, token)) - expand} {token.symbol}</Text>
                        <Text>Total PnL: {floatToken(botStatistic.totalBotPnL, token)} {token.symbol}</Text>
                      </>
                    ) : (
                      <Table w='full' h='auto'>
                        <Tbody>
                          <Tr>
                            <Th>Name</Th>
                            <Th>Value</Th>
                          </Tr> 
                          {
                            Object.keys(botStatistic).map((key, i) => {
                              var _key = key
                              if (_key === 'pendingWithdrawalAmount')
                                _key = 'pendingWithdrawal'
                              var value: string
                              if (i === 0)
                                value = botStatistic[key as keyof typeof botStatistic]
                              else 
                                value = `${parseFloat(ethers.utils.formatUnits(botStatistic[key as keyof typeof botStatistic], token.decimal)).toFixed(2)}$`
                              return (
                                <Tr key={i}>
                                  <Td>{_key}</Td>
                                  <Td>{value}</Td>
                                </Tr>
                              )
                            })
                          }
                        </Tbody>
                      </Table>
                    )
                  ) 
                }
              </VStack>
            </AccordionPanel>
            </>
          )}
    
        </AccordionItem>
      </Accordion>
      
      
    </Box>
    
  )
}

export default BotStatistic