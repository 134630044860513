import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { Tooltip, useToast } from "@chakra-ui/react";
import {
    Text,
    Input,
    Box,
    Button,
    InputRightElement,
    InputGroup,
    VStack,
    HStack,
    Divider,
    Center,
} from "@chakra-ui/react";
import { useEthersContext } from "../../context";
import { formatToken, formatLocale, floatToken } from "../../utils";
import { LockIcon } from "@chakra-ui/icons";
function NetworkCategory() {
    const { library } = useWeb3React();
    const { isDesktop, networkUserInfo, contracts } = useEthersContext();
    const toast = useToast();

    const {
        userInfo,
        config,
        token,
        approvingContract,
    } = useEthersContext();

    const space = isDesktop ? "5" : "3";

    const [input, setInput] = useState<string>("0");
    const [btnState, setBtnState] = useState<boolean>(false);

    function getReceiveWithdraw(withdraw: number | string) {
        return Number(withdraw) * (1 - Number(config.userWithdrawFee) / 1000);
    }


    async function handleWithdrawNetworkIncome() {
        toast({
            title: `Withdraw Network Income`,
            description: `Withdrawing ${parseFloat(input as string).toFixed(
                2
            )} ${token.symbol}...`,
            status: "info",
            duration: 9000,
            isClosable: true,
        });
        setBtnState(true);
        const amount = input.toString();
        console.log(`Start withdraw network income: ${amount}`);
        try {
            const amountWei = ethers.utils.parseUnits(amount, token.decimal);
            const tx = await contracts?.botContract.withdrawBunnyBotNetworkIncome(
                amountWei,
                {
                    gasPrice: await library.getGasPrice(),
                }
            );
            console.log(`withdraw network income hash: ${tx.hash}`);
            const receipt = await library.waitForTransaction(tx.hash, 1);
            toast.closeAll();
            if (receipt.status) {
                console.log(`withdraw network income done`);
                toast({
                    title: `Withdraw Network Income`,
                    description: `Withdraw ${parseFloat(
                        input as string
                    ).toFixed(2)} ${token.symbol} successfully`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.log(`withdraw network income fail`);
                toast({
                    title: `Withdraw Network Income`,
                    description: `Withdraw ${parseFloat(
                        input as string
                    ).toFixed(2)} ${token.symbol} failed`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (e: any) {
            toast.closeAll();
            let _e = "";
            if (e.reason !== undefined) {
                _e = e.reason;
            } else if (e.data.message !== undefined) {
                _e = e.data.message + ". Send some BNB to your wallet";
            }
            toast({
                title: `Withdraw Network Income`,
                description: `Withdraw ${parseFloat(
                    input as string
                ).toFixed(2)} ${token.symbol} failed. ${_e}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            console.log(`withdraw network income error: ${JSON.stringify(e)}`);
        }
        setBtnState(false);
        setInput("0");
    }

    return (
        <>
            <Box
                w="full"
                h="full"
                bg="white"
                borderRadius={space}
                p={space}
                color={userInfo.isNetworkUser && !networkUserInfo.isBlacklist ? "black" : "gray.500"}
            >
                <VStack w="full" align="left" mb={5}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Text as="b" mr='2'>Network Statistic</Text>
                        {
                            (!userInfo.isNetworkUser || networkUserInfo.isBlacklist) && (
                                <Tooltip label={networkUserInfo.isBlacklist ?
                                    "You are in Blacklist": 
                                    (<>{networkUserInfo.isWarning? "You are warning. Re-invest to avoid blacklist":"Only available for network user"}</>)}>
                                    <LockIcon />
                                </Tooltip>
                            )
                        }
                    </div>

                    <HStack w="full" justifyContent="left">
                        <VStack w="full" align="left">
                            <Text color="gray.500">Current Lock Income</Text>
                            <Text ml={space}>
                                {floatToken(networkUserInfo.currentLockIncome, token)}{" "}
                                {token.symbol}
                            </Text>
                        </VStack>
                        <Center height="50px">
                            <Divider orientation="vertical" />
                        </Center>
                        <VStack w="full" align="left">
                            <Text color="gray.500">Network Balancing</Text>
                            <Text ml={space}>
                                {networkUserInfo.totalLeftNodeChilds} L /{" "}
                                {networkUserInfo.totalRightNodeChilds} R (
                                {(networkUserInfo.totalLeftNodeChilds === '0' && networkUserInfo.totalRightNodeChilds === "0")
                                    ? "0.00"
                                    : <>
                                        {
                                            Number(networkUserInfo.totalLeftNodeChilds) > Number(networkUserInfo.totalRightNodeChilds) ?
                                                (Number(networkUserInfo.totalRightNodeChilds) / Number(networkUserInfo.totalLeftNodeChilds) * 100).toFixed(2) :
                                                (Number(networkUserInfo.totalLeftNodeChilds) / Number(networkUserInfo.totalRightNodeChilds) * 100).toFixed(2)
                                        }
                                    </>
                                }
                                %)
                            </Text>
                        </VStack>
                    </HStack>
                    <HStack w="full" justifyContent="left">
                        {/* <VStack w="full" align="left">
                            <Text color="gray.500">Current Node Income</Text>
                            <Text ml={space}>
                                {floatToken(networkUserInfo.currentTotalNodeIncome, token)}{" "}{token.symbol}
                            </Text>
                        </VStack> */}
                        <VStack w="full" align="left">
                            <Text color="gray.500">Current Network Revenue</Text>
                            <Text ml={space}>
                                {floatToken(networkUserInfo.currentNetworkRevenue, token)}{" "}{token.symbol}
                            </Text>
                        </VStack>
                        <Center height="50px">
                            <Divider orientation="vertical" />
                        </Center>
                        <VStack w="full" align="left">
                            <Text color="gray.500">Network (Blacklist/Total)</Text>
                            <Text ml={space}> {networkUserInfo.totalBlacklist} BL / {networkUserInfo.totalNodeChilds} Total Users
                            </Text>
                        </VStack>
                    </HStack>
                </VStack>
                <VStack w="full" align="left" mt={isDesktop ? 16 : space}>
                    <Text as="b">Network Income</Text>
                    <HStack w="full" justifyContent="left">
                        <VStack w="full" align="left">
                            <Text color="gray.500">Current Income</Text>
                            <Text ml={space}>
                                {floatToken(networkUserInfo.currentNetworkIncome, token)}{" "}{token.symbol}
                            </Text>
                        </VStack>
                        <Center height="50px">
                            <Divider orientation="vertical" />
                        </Center>
                        <VStack w="full" align="left">
                            <Text color="gray.500">Total Network Income</Text>
                            <Text ml={space}>
                                {floatToken(networkUserInfo.totalNetworkIncome,token)}{" "}{token.symbol}
                            </Text>
                        </VStack>
                    </HStack>
                    <Text color="gray.500">Withdraw</Text>
                    <HStack w="full" spacing={5} alignItems="center">
                        <InputGroup>
                            <Input
                                isInvalid={
                                    parseFloat(input) < 0 ||
                                    parseFloat(input) >
                                    parseFloat(formatToken(networkUserInfo.currentNetworkIncome,token))
                                }
                                errorBorderColor="red.300"
                                type="number"
                                name="withdrawInvest"
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value);
                                }}
                                placeholder="0"
                                isDisabled={!userInfo.isNetworkUser ||
                                    networkUserInfo.isBlacklist}
                            />
                            <InputRightElement>
                                <Button
                                    disabled={
                                        btnState ||
                                        approvingContract ||
                                        !userInfo.isNetworkUser||
                                        networkUserInfo.isBlacklist
                                    }
                                    onClick={() => {
                                        setInput(
                                            formatToken( networkUserInfo.currentNetworkIncome,token)
                                        );
                                    }}
                                >
                                    Max
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            w="40%"
                            colorScheme="blue"
                            isLoading={btnState}
                            loadingText={!isDesktop ? "" : "Withdrawing"}
                            isDisabled={
                                btnState ||
                                parseFloat(input) >
                                parseFloat(formatToken(networkUserInfo.currentNetworkIncome,token)) ||
                                parseFloat(input) <= 0 ||
                                !userInfo.isNetworkUser ||
                                networkUserInfo.isBlacklist
                            }
                            onClick={handleWithdrawNetworkIncome}
                        >
                            Withdraw
                        </Button>
                    </HStack>
                    <Text color="gray.500" fontSize="13px">
                        Withdraw fee:{" "}
                        {(Number(config.userWithdrawFee) * 100) / 1000}%.
                        Receive amount: ${getReceiveWithdraw(input)}{" "}
                        {token.symbol}
                    </Text>
                </VStack>
            </Box>
        </>
    );
}

export default NetworkCategory;
