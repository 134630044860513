import React from 'react'
import {
	Button,
	VStack,
	Table,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { AdminConfigInterface } from '../utils'
function AdminConfigParameters({adminConfig, getAdminConfig}:{adminConfig: AdminConfigInterface, getAdminConfig: () => void}) {
  return (
    <VStack w='full' align='left'>
        <Accordion allowToggle width='full' onChange={getAdminConfig} bg='white' pt={2} >
          <AccordionItem>
            {({ isExpanded }) => (
            <>
              <h2>
              <AccordionButton>
                <Box as="b" flex='1' textAlign='left' >
                  Admin Parameters
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
            <AccordionPanel >
            <Button w='150px' colorScheme='blue' onClick={getAdminConfig}>Get</Button>
              <Table w='full' h='auto'>
                <Tbody>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Value</Th>
                  </Tr>
                  {
                    (Object.keys(adminConfig).length > 0) && Object.keys(adminConfig).map((key: string, i) => {
                      var value
                      if (i === 0)
                        value = `${adminConfig[key as keyof typeof adminConfig]} %`
                      else if (i === 1) {
                        value = `${adminConfig[key as keyof typeof adminConfig]} $`
                      }
                      else if (i === 2)
                        value = `${adminConfig[key as keyof typeof adminConfig]} times`
                      else if (i > 1 &&  i < Object.keys(adminConfig).length - 3)
                        value = `${adminConfig[key as keyof typeof adminConfig]} min`
                      else if ( i >= Object.keys(adminConfig).length - 3)
                        value = `${adminConfig[key as keyof typeof adminConfig].toFixed(2)} $`
                      return (
                        <Tr key={i}>
                          <Td>{key}</Td>
                          <Td>{value}</Td>
                        </Tr>
                      )
                    })
                  }
                </Tbody>
              </Table>
            
            </AccordionPanel>
            </>
              )}
          </AccordionItem>
        </Accordion>
        
      </VStack>
  )
}

export default AdminConfigParameters