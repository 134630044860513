import abi from '../abi/CrowdFunding.json'
import abi_erc20 from '../abi/erc20.json'
import abi_manage_token from '../abi/ManageOwnerToken.json'
import React from 'react'
import { ethers } from 'ethers'

import BotContractAbi from '../abi/diamond/BotContract.json'
import ConfigContractAbi from '../abi/diamond/ConfigContract.json'
import NetworkContractAbi from '../abi/diamond/NetworkContract.json'
import SyncContractAbi from '../abi/diamond/SyncContract.json'
import UserContractAbi from '../abi/diamond/UserContract.json'
import NodeContract from '../abi/diamond/NodeContract.json'
import NodeStatisticContract from '../abi/diamond/NodeStatisticContract.json'
import ChildContract from '../abi/diamond/ChildContract.json'

export const Constants = {
	contractAbi: {
		botContract: BotContractAbi, 
		configContract: ConfigContractAbi, 
		networkContract: NetworkContractAbi, 
		syncContract: SyncContractAbi, 
		userContract: UserContractAbi,
		nodeContract: NodeContract,
		nodeStatisticContract: NodeStatisticContract,
		childContract: ChildContract
	},
	erc20Abi: abi_erc20,
	tokenAddress: process.env.REACT_APP_USD_TOKEN_ADDRESS,
	networkChainId: Number(process.env.REACT_APP_NETWORK_CHAINID),
	explorerUrl: process.env.REACT_APP_EXPLORER_URL!,
	apiUrl: process.env.REACT_APP_API_URL,
	websiteUrl: process.env.REACT_APP_WEBSITE_URL,
	mobileWidth: process.env.REACT_APP_MOBILE_WIDTH,
	endpointTestnet: process.env.REACT_APP_ENDPOINT_TESTNET!,
	endpointMainnet: process.env.REACT_APP_ENDPOINT_MAINNET!,
	zeroAddress: '0x0000000000000000000000000000000000000000',
	manageTokenContractAddress: process.env.REACT_APP_MANAGE_TOKEN_CONTRACT_ADDRESS!,
	contractManageTokenAbi: abi_manage_token,
	encryptAPIPassword: process.env.REACT_APP_API_ENPD
}

export const selectBorder = (desktop: boolean) => {
	return desktop ? '10px' : '5px'
}

export const selectPadding = (desktop: boolean) => {
	return desktop ? 5 : 3
}

export const selectCenterHeight = (desktop: boolean) => {
	return desktop ? 'calc(100vh - 310px)' : 'calc(100vh - 85px)'
}


export const checkAddress = (address: string) => {
	return ethers.utils.isAddress(address)
}

export const truncateAddress = (address: string | null | undefined) => {
	if (!address) return "No Account";
	const match = address.match(
		/^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/
	);
	if (!match) return address;
	return `${match[1]}…${match[2]}`;
};

export async function getApi(method: string, params?: object, accessToken?: string) {
	var url = `${Constants.apiUrl}/api${method}`
	const options: RequestInit = {
		method: 'post',
		mode: 'cors',
		headers: {
			'Content-Type': 'Application/json',
			'Authorization': 'Bearer ' + accessToken,
		},
		body: JSON.stringify(params)
	}
	return fetch(url, options)
		.then(res => {
			// console.log(res.status)
			return res.json()
		})
		.then(res => {
			// console.log(`Res: ${JSON.stringify(res, null, 4)}`)
			return res
		})
}

export const useViewPort = () => {
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);
	//Min width mobile = 400px
	return { width };
};

export function formatToken(amount: string, token: Token) {
	return ethers.utils.formatUnits(amount, token.decimal)
}
export function floatToken(amount: string, token: Token) {
	const _amount = ethers.utils.formatUnits(amount, token.decimal)
	return parseFloat(_amount).toFixed(2)
}

export function formatLocale(amount: string, token: Token) {
	// console.log(`formatLocale Amount: ${amount}`)
	return parseFloat(floatToken(amount, token)).toLocaleString(undefined, { maximumFractionDigits: 2 })
}

export const ENUM_USER = {
	isSuspended: 9,
	isUser: 10,
	approve: 11,
	balance: 12,
	isNetworkUser: 13,
	isWaitingForNetworkApprove: 15,
}
export interface UserInfo {
	id: string,
	referrer: string,
	currentPnL: string,
	totalPnL: string,
	currentInvest: string,
	currentCommission: string,
	totalCommission: string,
	withdrawInvestAmount: string,
	isPendingWithdrawal: false,
	isSuspended: false,
	isUser: false,
	approve: false, //Keep this variable at this position
	balance: string, //Keep this variable at this position
	isNetworkUser: false, //V3 network Keep this variable at this position
	username: string, 
	isWaitingForNetworkApprove: false,//15
	networkRegisterTimestamp: string
}

//V3 network
export interface NetworkUserStruct {
	id: string,
	currentLockIncome: string,
	totalNetworkIncome: string,
	currentNetworkIncome: string,
	totalLeftNodeChilds: string,
	totalRightNodeChilds: string,
	totalNodeChilds: string,
	currentLeftNodeIncome: string,
	currentRightNodeIncome: string,
	currentTotalNodeIncome: string,
	totalBlacklist: string,
	currentNetworkRevenue: string,
	isWarning: false,
	isBlacklist: false,
	isRejected: false,
}
// export type NetworkUserStruct = Record<string, string | number | boolean>;

export interface Token {
	address: string,
	symbol: string,
	decimal: number
}

export interface Config {
	userWithdrawFee: string,
	referralFee: string,
	platformFee: string,
	minimumInvest: string,
	remainReserve: string,
	minReserve: string,
	normalReserve: string,
	childTokenBalanceThreshold: string //minimum child balance to distribute
    childWETHBalanceThreshold: string //minimum child balance to distribute
}

export interface ReferralList {
	[key: string]: string;
}

export interface AdminConfigInterface {
	profitPercent: number,
	minProfitBalance: number,
	totalDistribute: number,
	websiteInterval: number,
	profitInterval: number,
	withdrawInterval: number,
	reserveInterval: number,
	autoProfitInterval: number,
	minDistributeTime: number,
	maxDistributeTime: number,
	paceDistributeTime: number,
	expandParam: number,
	autoProfitCurrentInvest: number,
	autoProfitBotBalance: number
}

export interface BotStatisticInterface {
	totalBotUser: string,
	totalBotPnL: string,
	currentBotPnL: string,
	totalBotInvest: string,
	currentBotInvest: string,
	totalBotCommission: string,
	currentBotCommission: string,
	pendingWithdrawalAmount: string,
	totalNetworkUsers: string; //V3 network
	currentTokenPnL: string;
	totalTokenPnL: string;
	totalManagementFee: string;
	maxNetworkDepth: string;
	contractBalance: string, //Keep at the end
}

export interface TokenOwnerConfigInterface {
	totalSellToken: string,
	maxSellToken: string,
	tokenPrice: string,
	freezing: boolean,
	availableSellToken: string,
	usdSellToken: string,
	bonusRate: string,
	totalBonus: string,
	bonusRateUSD: string,
	totalBonusUSD: string,
	contractKeepRate: string,
	presaleRound: string,
	presaleTotal: string,
	presaleStart: string,
	presalePrice: string,//v2.3
	ibIIBUsers: string[]//v2.3
}

export interface TokenOwnerUserInterface {
	isUser: boolean,
	id: string,
	balance: string,
	history: [],
	totalUserBonus: string,
	totalUSDBuy: string,
	totalUSDBonus: string,
	ibTotalClaimed: string, //v2.2
	ibTotalClaimedUSD: string, //v2.2
	isIB: boolean,//v2.2
	ibBonus: string,//v2.2
	ibBonusUSD: string,//v2.2
	ibIsIIB: boolean,//v2.3
	ibIIBBonusUSD: string,//v2.3
	ibIIBTotalClaimedUSD: string,//v2.3
	ibPayoutRatio: string,//v2.3
	approve: boolean,
}

export interface TokenSaleIBConfig {
	ibTotalBonusCondition: string;
	ibTotalUserCondition: string;
	ibPurchasedPerUserCondition: string;
	ibBonusPercentage: string;
	ibTotalUserClaimed: string;
	ibCurrentIBExtra: string;
	ibCurrentIBExtraUSD: string;
	ibTotalIBExtra: string;
	ibCurrentIIBUSD: string;//v2.3
}