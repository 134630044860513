import {
	Flex,
	Spinner,
  Image
} from "@chakra-ui/react";
import { bunnybot } from '../assets';
function Loading() {
  const isDesktop = window.innerWidth > 726;
  return (
    <>
    <Flex flexDirection='column' w='full' h={ isDesktop ? "calc(100vh - 250px)" : "calc(100vh - 85px)" } bg='white' justifyContent='center' alignItems='center'>
      <Image src={bunnybot} w='auto' h='60px'></Image>
      <Spinner color='blue' size='md' />
    </Flex>
    </>
  )
}

export default Loading