import {
	HStack,
	VStack,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { NetworkCategory, ProfitCategory, InvestmentCategory, CommissionCategory } from './category'
import { useEthersContext } from '../context'
import { selectPadding } from '../utils'
function Categories() {
  const { isDesktop } = useEthersContext()
	const space = selectPadding(isDesktop)
	const panels = [<InvestmentCategory/>, <NetworkCategory/>, <ProfitCategory/>, <CommissionCategory/>]
	const panelNames = ['Invest', 'Network', 'Profit', 'Commission']
	return (
		<VStack w='full' h='auto' mt={space} spacing={space}>
		{
			isDesktop ? (
				<>
				<HStack align='left' w='full' h='435px' spacing={space}>
					{panels[0]}
					{panels[1]}
				</HStack>
				<HStack align='left' w='full' h='220px' spacing={space}>
					{panels[2]}
					{panels[3]}
				</HStack>
			</>
			) : (
				<Tabs size="sm" variant='unstyled' w='full' >
				<TabList h='40px' w='full'>
					<HStack w='full' h='full' bg='rgb(56, 61, 81)' color='white'  alignItems='center' justifyContent='space-between' p={1}>
					{
						panelNames.map((name, index) => {
							return (
								<Tab key={index} _selected={{ color: 'black', bg: 'white'}}>{name}</Tab>
							)
						})
					}
					</HStack>
				</TabList>
				<TabPanels>
					{
						panels.map((panel, index) => {
							return (
								<TabPanel p={0} key={index}>
									{panel}
								</TabPanel>
							)
						})
					}
				</TabPanels>
			</Tabs>
			)
		}
		</VStack>
	)
}

export default Categories