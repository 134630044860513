
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  VStack,
  HStack,
  Text,
  Input,
} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import Connectors from "./Connectors";
import { cbw, mm, wc, tw } from '../assets'
import React from "react";

interface _ApproveModal {
  modalHeader: string, 
  modalBody: string, 
  modalBtnText: string, 
  isOpenApproveModal: boolean, 
  modalBtnHandler: ()=>void, 
  onCloseApproveModal: ()=>void
}

export const UnderConstructionModal = (props : {isOpen: boolean, onClose: ()=> void}) => {
  const { isOpen, onClose } = props
  return (
      <>
      <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w='90%' textAlign='center' justifyItems='center'>
            <ModalHeader >UNDER CONSTRUCTION</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb='3'>
              Please come back later!
            </ModalBody>   
          </ModalContent>
      </Modal>
      </>
  );
};

export const TransferBFiModal = (props : {isOpen: boolean, onClose: ()=> void, onConfirm: (receiver: string, amount: string)=> void}) => {
  const { isOpen, onClose } = props
  const [ receiver, setReceiver ] = React.useState('')
  const [ amount, setAmount ] = React.useState('')
  return (
      <>
      <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w='90%' textAlign='center' justifyItems='center'>
            <ModalHeader >Transfer BFi</ModalHeader>
            <ModalCloseButton />
            <ModalBody mb='3'>
              <Input 
                placeholder="Receiver Address"
                type="text"
                w='100%'
                mb='3'
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
              />
              <Input
                placeholder="Amount BFi"
                type="number"
                w='100%' 
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr='3' onClick={props.onClose}>Cancel</Button>
              <Button 
                colorScheme="blue" 
                onClick={()=>{props.onClose(); props.onConfirm(receiver, amount); setReceiver(''); setAmount('')}}
                isDisabled={!receiver ||!amount || amount === '0'}
              >Confirm</Button>
            </ModalFooter>   
          </ModalContent>
      </Modal>
      </>
  );
};

export const ApproveModal = (
  {modalHeader, modalBody, modalBtnText, modalBtnHandler, isOpenApproveModal, onCloseApproveModal} : _ApproveModal) => {
  return (
      <>
      <Modal isOpen={isOpenApproveModal} onClose={onCloseApproveModal}>
          <ModalOverlay />
          <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBody}</ModalBody>
          <ModalFooter>
              <Button onClick={onCloseApproveModal}>
              Cancel
              </Button>
              <Button colorScheme='red' onClick={modalBtnHandler}>
              {modalBtnText}
              </Button>
          </ModalFooter>
          </ModalContent>
      </Modal>
      </>
  );
};

export function SelectWalletModal({isOpen, closeModal}:{isOpen: boolean, closeModal: ()=>void}) {
  const { activate } = useWeb3React();
  const setProvider = (type: string) => {
    window.localStorage.setItem("provider", type);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent w="300px">
        <ModalHeader>Select Wallet</ModalHeader>
        <ModalCloseButton
          _focus={{
            boxShadow: "none"
          }}
        />
        <ModalBody paddingBottom="1.5rem">
          <VStack>
            <Button
              variant="outline"
              onClick={() => {
                console.log(`activate trust wallet`)
                activate(Connectors.injected);
                setProvider("injected");
                closeModal();
              }}
              w="100%"
            >
              <HStack w="100%" justifyContent="center">
                <Image
                  src={tw}
                  alt="Trust Wallet Logo"
                  width={25}
                  height={25}
                  borderRadius="3px"
                />
                <Text>Trust Wallet</Text>
              </HStack>
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                console.log(`activate metamask`)
                activate(Connectors.injected);
                setProvider("injected");
                closeModal();
              }}
              w="100%"
            >
              <HStack w="100%" justifyContent="center">
                <Image
                  src={mm}
                  alt="Metamask Logo"
                  width={25}
                  height={25}
                  borderRadius="3px"
                />
                <Text>Metamask</Text>
              </HStack>
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                console.log(`activate walletconnect`)
                activate(Connectors.injected);
                setProvider("injected");
                closeModal();
              }}
              w="100%"
            >
              <HStack w="100%" justifyContent="center">
                <Text>Connect Wallet</Text>
              </HStack>
            </Button>
            
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}