
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useToast } from '@chakra-ui/react'
import {
	Text,
	Input,
	Box,
	Button,
	InputRightElement,
	InputGroup,
	VStack,
	HStack,
	Divider,
	Center
} from '@chakra-ui/react'
import { useEthersContext } from '../../context'
import { formatToken, formatLocale, floatToken } from '../../utils'

function InvestmentCategory() {
  const { library } = useWeb3React()
  const toast = useToast()

  const { userInfo, config, token, contracts, approvingContract, isDesktop, handleApproveContract, isBdAccess } = useEthersContext()
  const [input, setInput] = useState<string>('0')
	const [btnState, setBtnState] = useState<boolean>(false)
  const space = isDesktop ? "5" : "3";

  const [inputWithdraw, setInputWithdraw] = useState<string>('0');
  const [btnWithdrawState, setBtnWithdrawState] = useState<boolean>(false);

  useEffect(() => {
    console.log(`update config invest`)
  }, [config])
  

  const handleInvestment = async () => {
		toast({
			title: `Investment`,
			description: `Investing ${parseFloat(input as string).toFixed(2)} ${token.symbol}`,
			status: 'loading',
			duration: 30000,
			isClosable: true,
		})
		setBtnState( true)
		console.log(`Start invest: ${input}`)
		try {
			const amount = ethers.utils.parseUnits(input.toString(), token.decimal)
      
			const tx = await contracts?.botContract.investBunnyBotAndEarnProfit(amount, {
				gasPrice: await library.getGasPrice()
			})
			console.log(`Invest hash: ${tx.hash}`)
			const receipt = await library.waitForTransaction(tx.hash, 1)
			toast.closeAll()
			if (receipt.status) {
				console.log(`Invest done`)
				toast({
					title: `Investment`,
					description: `Invest ${parseFloat(input as string).toFixed(2)} ${token.symbol} successfully`,
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
			} else {
				console.log(`Invest fail`)
				toast({
					title: `Investment`,
					description: `Invest ${parseFloat(input as string).toFixed(2)} ${token.symbol} failed`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}
		catch (e: any) {
			toast.closeAll()
      let _e = ''
      if (e.reason !== undefined){
        _e = e.reason
      } else
      if (e.data.message !== undefined) {
        _e = e.data.message + '. Send some BNB to your wallet'
      }
      console.log(`_e: ${JSON.stringify(_e)}`)
			toast({
				title: `Investment`,
				description: `Invest ${parseFloat(input as string).toFixed(2)} ${token.symbol} failed. ${_e}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			})
			console.log(`Invest error: ${JSON.stringify(e)}`)
		}

		setBtnState( false)
		setInput('0')
	}
  
  function getReceiveWithdraw(withdraw: number | string) {
    return Number(withdraw) * (1 - Number(config.userWithdrawFee) / 1000);
  }

  const handleWithdrawInvest = async () => {
    toast({
      title: `Withdraw Investment`,
      description: `Submitting ${parseFloat(inputWithdraw as string).toFixed(2)} ${
        token.symbol
      }`,
      status: "info",
      duration: 9000,
      isClosable: true,
    });
    setBtnWithdrawState(true);
    const amount = inputWithdraw.toString();
    
    try {
      const amountWei = ethers.utils.parseUnits(amount, token.decimal);
      console.log(`Start withdraw invest: ${amount} | ${amountWei}`);
      const tx = await contracts?.botContract.submitWithdrawInvestment(amountWei, {
        gasPrice: await library.getGasPrice(),
      });
      console.log(`withdraw invest hash: ${tx.hash}`);
      const receipt = await library.waitForTransaction(tx.hash, 1);
      if (receipt.status) {
        console.log(`withdraw invest done`);
        toast({
          title: `Withdraw Investment`,
          description: `Submit withdraw ${parseFloat(inputWithdraw as string).toFixed(
            2
          )} ${token.symbol}. Your withdrawal is pending`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log(`withdraw invest fail`);
        toast({
          title: `Withdraw Investment`,
          description: `Submit withdraw ${parseFloat(inputWithdraw as string).toFixed(
            2
          )} ${token.symbol} failed`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e: any) {
      toast.closeAll();
      let _e = "";
      if (e.reason !== undefined) {
        _e = e.reason;
      } else if (e.data.message !== undefined) {
        _e = e.data.message + ". Send some BNB to your wallet";
      }
      console.log(`_e: ${JSON.stringify(_e)}`);
      toast({
        title: `Withdraw Investment`,
        description: `Submit withdraw ${parseFloat(inputWithdraw as string).toFixed(
          2
        )} ${token.symbol} error. ${_e}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(`withdraw invest error: ${JSON.stringify(e)}`);
    }
    setBtnWithdrawState(false);
    setInputWithdraw('0');
  };

  const handleCanclePendingWithdrawInvest = async () => {
    toast({
      title: `Cancel pending investment withdrawal`,
      description: `Submitting transaction...`,
      status: "info",
      duration: 9000,
      isClosable: true,
    });
    setBtnWithdrawState(true);
    console.log(`Cancel pending investment withdrawal`);
    try {
      const tx = await contracts?.botContract.cancelPendingWithdrawal(userInfo.id, {
        gasPrice: await library.getGasPrice(),
      });
      console.log(`Cancel pending investment withdrawal: ${tx.hash}`);
      const receipt = await library.waitForTransaction(tx.hash, 1);
      if (receipt.status) {
        console.log(`Canceling pending investment withdrawal done`);
        toast({
          title: `Cancel pending investment withdrawal`,
          description: `Done`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        console.log(`Canceling pending investment withdrawal failed`);
        toast({
          title: `Cancel pending investment withdrawal`,
          description: `Failed`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e: any) {
      toast.closeAll();
      let _e = "";
      if (e.reason !== undefined) {
        _e = e.reason;
      } else if (e.data.message !== undefined) {
        _e = e.data.message + ". Send some BNB to your wallet";
      }
      console.log(`_e: ${JSON.stringify(_e)}`);
      toast({
        title: `Cancel pending investment withdrawal`,
        description: `Canceling pending investment withdrawal error. ${_e}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(`Canceling withdraw invest error: ${JSON.stringify(e)}`);
    }
    setBtnWithdrawState(false);
    setInputWithdraw('0');
  };

  return (
    <>
    <Box w='full' h='full' bg='white' borderRadius={space} p={space}  >
      <VStack w='full' align='left'>
          <Text as='b'>Investment</Text>
          <HStack w='full' justifyContent='left'>
						<VStack w='full' align='left'>
              <Text color='gray.500'>Investable</Text>
              <Text  ml={space}>{formatLocale(userInfo.balance, token)} {token.symbol}</Text>
            </VStack>
            <Center height='50px'>
              <Divider orientation='vertical' />
            </Center>
						<VStack w='full' align='left'>
              <Text color='gray.500'>Current invest</Text>
              <Text  ml={space}>{formatLocale(userInfo.currentInvest, token)} {token.symbol}</Text>
            </VStack>
            
          </HStack>
          <Text color='gray.500'>Invest</Text>
          <HStack w='full' spacing={5} alignItems='center'>
            <InputGroup>
              <Input
                isInvalid={(parseFloat(input) < parseFloat(formatToken(config.minimumInvest, token)) && parseFloat(input) > 0) || ( isBdAccess && (parseFloat(input) > parseFloat(formatToken(userInfo.balance, token))))}
                errorBorderColor='red.300'
                type='number'
                name='invest'
                value={input}
                onChange={(e)=>{setInput(e.target.value)}}
                placeholder='0'
                disabled={ btnState || approvingContract}
              />
              <InputRightElement>
                <Button disabled={btnState || approvingContract} onClick={() => { setInput(formatToken(userInfo.balance, token)) }}>
                  Max
                </Button>
              </InputRightElement >
            </InputGroup>
            <Button
              w='40%'
              colorScheme='blue'
              isLoading={btnState || approvingContract}
              loadingText={ !btnState ? '': !isDesktop ? '': 'Investing'}
              isDisabled={btnState || approvingContract || input ==='' ||  (!isBdAccess && (parseFloat(input) > parseFloat(formatToken(userInfo.balance, token)))) || parseFloat(input) < parseFloat(formatToken(config.minimumInvest, token))}
              onClick={() => {
                if (userInfo.approve) {
                  console.log('invest')
                  handleInvestment()
                }
                else {
                  console.log('invest approve')
                  handleApproveContract()
                }
              }
              }>Invest
            </Button>
          </HStack>
          <Text color='gray.500' justifyContent='right' fontSize='13px'>
            Minimum invest amount: {formatToken(config.minimumInvest, token)} {token.symbol}
          </Text>
      </VStack>
      <VStack w="full" align="left" mt={space}>
        <Text as="b">Withdraw Investment</Text>
        <HStack w="full" justifyContent="left">
          <VStack w="full" align="left">
            <Text color="gray.500">Available Withdrawal</Text>
            <Text ml={space}>
              {formatLocale(userInfo.currentInvest, token)} {token.symbol}
            </Text>
          </VStack>
          <Center height="50px">
            <Divider orientation="vertical" />
          </Center>
          <VStack w="full" align="left">
            <Text color="gray.500">Pending Withdrawal</Text>
            <Text ml={space}>
              {formatLocale(userInfo.withdrawInvestAmount, token)} {token.symbol}
            </Text>
          </VStack>
        </HStack>
        <Text color="gray.500">Withdraw</Text>
        <HStack w="full" spacing={5} alignItems="center">
          <InputGroup>
            <Input
              isInvalid={
                parseFloat(inputWithdraw) < 0 ||
                parseFloat(inputWithdraw) > parseFloat(formatToken(userInfo.currentInvest, token))
              }
              errorBorderColor="red.300"
              type="number"
              name="withdrawInvest"
              value={inputWithdraw}
              onChange={(e) => {
                setInputWithdraw(e.target.value);
              }}
              placeholder="0"
              disabled={userInfo.isPendingWithdrawal || approvingContract}
            />
            <InputRightElement>
              <Button
                disabled={
                  userInfo.isPendingWithdrawal || btnWithdrawState || approvingContract
                }
                onClick={() => {
                  setInputWithdraw(formatToken(userInfo.currentInvest, token));
                }}
              >
                Max
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            w="40%"
            colorScheme={userInfo.isPendingWithdrawal ? "red":"blue"}
            isLoading={btnWithdrawState}
            isDisabled={
              (btnWithdrawState ||
              approvingContract ||
              (parseFloat(inputWithdraw) > 20000) ||
              parseFloat(inputWithdraw) > parseFloat(formatToken(userInfo.currentInvest, token)) ||
              (parseFloat(inputWithdraw) <= 0 )) && !userInfo.isPendingWithdrawal
            }
            onClick={
              userInfo.approve ? (userInfo.isPendingWithdrawal ? handleCanclePendingWithdrawInvest : handleWithdrawInvest) : handleApproveContract
            }
          >
            {userInfo.isPendingWithdrawal ? "Cancel" : "Withdraw"}
          </Button>
        </HStack>
        {
          (parseFloat(inputWithdraw) > 20000) ? (
            <>
              <Text color="red" fontSize="13px">
                Maximum withdraw per time: 20.000 {token.symbol}
              </Text>
            </>
          ) : (
            <>
            <Text color="gray.500" fontSize="13px">
              Withdraw fee: {(Number(config.userWithdrawFee) * 100) / 1000}%.
              Receive amount: ${getReceiveWithdraw(inputWithdraw)} {token.symbol}
            </Text>
            </>
          )
        }
      </VStack>
    </Box>
  
    </>
  )
}

export default InvestmentCategory