import {
    truncateAddress,
    Constants,
    selectPadding,
    selectBorder,
    formatLocale,
} from "../utils";
import {
    Text,
    Box,
    Flex,
    Button,
    useToast,
    Divider,
    HStack,
    Tooltip,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    FocusLock,
    PopoverArrow,
    Input,
    PopoverCloseButton,
    ButtonGroup,
    InputGroup,
    InputRightElement,
    VStack,
} from "@chakra-ui/react";
import { useEthersContext } from "../context";
import { CopyIcon, EditIcon, CloseIcon } from "@chakra-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ethers, BigNumber } from "ethers";
import { SiCashapp } from "react-icons/si";
import React from "react";
import { useWeb3React } from "@web3-react/core";
function AccountInfoAbove() {
    const { userInfo, token } = useEthersContext();
    return (
        <>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">Invest</Text>
                <Text as="b">
                    {formatLocale(userInfo.currentInvest, token)}$
                </Text>
            </Box>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">Profit</Text>
                <Text as="b">{formatLocale(userInfo.currentPnL, token)}$</Text>
            </Box>
        </>
    );
}
function AccountInfoBelow() {
    const { userInfo, token, networkUserInfo } = useEthersContext();
    return (
        <>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">Commission</Text>
                <Text as="b">
                    {formatLocale(userInfo.currentCommission, token)}$
                </Text>
            </Box>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">Network</Text>
                <Text as="b">{formatLocale(networkUserInfo.currentNetworkIncome.toString(), token)}$</Text>
            </Box>
        </>
    );
}

function AccountInfoBottom({
    totalTokenInUSD,
}: {
    totalTokenInUSD: ethers.BigNumber;
}) {
    const { token, networkUserInfo } = useEthersContext();
    return (
        <>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">Network Lock</Text>
                <Text as="b">{formatLocale(networkUserInfo.currentLockIncome, token)}$</Text>
            </Box>
            <Box w="full" height="50px" justifyContent="center">
                <Text color="gray.500">BFi Token</Text>
                <Text as="b">
                    {formatLocale(totalTokenInUSD.toString(), token)}$
                </Text>
            </Box>
        </>
    );
}

function AccountInfoHeader({
    totalTokenInUSD,
}: {
    totalTokenInUSD: ethers.BigNumber;
}) {
    const { account, isDesktop, userInfo, token, contracts, networkUserInfo } = useEthersContext();
    const { library } = useWeb3React();
    const { onOpen, onClose, isOpen } = useDisclosure()
    const [ username, setUsername ] = React.useState<string>("")
    const firstFieldRef = React.useRef(null)
    const toast = useToast();
    const totalBalance = ethers.BigNumber.from(networkUserInfo.currentLockIncome)
        .add(ethers.BigNumber.from(userInfo.currentCommission))
        .add(ethers.BigNumber.from(userInfo.currentInvest))
        .add(ethers.BigNumber.from(userInfo.currentPnL))
        .add(ethers.BigNumber.from(networkUserInfo.currentNetworkIncome))
        .add(totalTokenInUSD);

    async function copyAddress(text: string | null, result: boolean) {
        toast.closeAll();
        if (result) {
            toast({
                title: `Copy Successful`,
                description: `Account ${truncateAddress(
                    account
                )} copied to clipboard`,
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } else {
            toast({
                title: `Can't copy`,
                description: `Account ${truncateAddress(account)} copy failed`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    const handleChangeUsername = async () => {
        try {
            toast.closeAll();   
            if (username.length > 20) {
				console.log(`Register fail max 15 characters`);
                toast({
                    title: `Register Account`,
                    description: `Maximum 15 characters allowed.`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
			}
            toast({
                title: `Change Username`,
                description: `Changing username to "${username}"`,
                status: "info",
                duration: 9000,
                isClosable: true,
            });
  

            const tx = await contracts?.userContract?.setUsername(account, username, {
                gasPrice: await library.getGasPrice(),
            });
            console.log(`Change Username hash: ${tx.hash}`);
            const receipt = await library.waitForTransaction(tx.hash, 1);
            toast.closeAll();
            if (receipt.status) {
                console.log(`Change Username done`);
                toast({
                    title: `Change Username`,
                    description: `Change username to "${username}" successfully`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
            } else {
                console.log(`Change username fail`);
                toast({
                    title: `Change Username`,
                    description: `Change username failed. Check your BNB balance!`,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (e: any) {
            let _e = "";
            if (e.reason !== undefined) {
                _e = e.reason;
            } else if (e.data.message !== undefined) {
                _e = e.data.message + ". Send some BNB to your wallet";
            }
            console.log(`_e: ${JSON.stringify(_e)}`);
            toast.closeAll();
            toast({
                title: `Change Username`,
                description: `Change username error, ${_e}`,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            console.log(`Change username error: ${JSON.stringify(e)}`);
        }
    };

    const display = (
        <Flex w="full" alignItems="center" justifyContent="flex-end">
            
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                
                <Text as="b" color="gray.500" >
                    {" "}{userInfo.username}
                </Text>
                
            </div>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement='bottom'
            >
                <PopoverTrigger>
                    {/* <IconButton size="sm" icon={<EditIcon />} /> */}
                    <Button variant="link">
                        <Tooltip label="Change username">
                            <EditIcon />
                        </Tooltip>
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <FocusLock persistentFocus={true}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <InputGroup>
                            <Input
                                type="text"
                                name="referral"
                                value={username}
                                onChange={(e) => {
                                    if (e.target.value.length <= 15) {
                                        setUsername(e.target.value);
                                    }
                                }}
                                placeholder="Enter new Username"
                                colorScheme='teal'
                                color='black'
                            />
                            <InputRightElement>
                                <Button
                                    variant='ghost'
                                    onClick={() => {setUsername("")}}
                                >
                                    <CloseIcon boxSize={3}/>
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <ButtonGroup mt={3} display='flex' justifyContent='flex-end'>
                            <Button colorScheme="red" onClick={onClose}>
                            Cancel
                            </Button>
                            <Button isDisabled={username===''} colorScheme='blue' onClick={()=> {onClose(); handleChangeUsername();}}>
                            Process
                            </Button>
                        </ButtonGroup>
                    </FocusLock>
                </PopoverContent>
            </Popover>
            <a
                href={(Constants.explorerUrl as string) + account}
                target="_blank"
                rel="noreferrer"
            >
                <Button color="gray.500" variant="link">
                    {isDesktop ? account : truncateAddress(account)}
                </Button>
            </a>
            <CopyToClipboard
                text={String(account)}
                onCopy={(text, result) => copyAddress(text, result)}
            >
                <Button variant="link">
                    <Tooltip label="Copy account">
                        <CopyIcon />
                    </Tooltip>
                </Button>
            </CopyToClipboard>
        </Flex>
    );
    return (
        <Box
            h={isDesktop ? "40px" : "auto"}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {
                isDesktop ? (
                    <>
                    <Flex flex="1" pl="3">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <SiCashapp size={20} />
                            <Text ml="2" as="b" fontSize="17px">
                                {formatLocale(totalBalance.toString(), token)}
                            </Text>
                        </div>
                    </Flex>
                    <Flex flex="4">{display}</Flex>
                    </>
                ):(
                    <>
                    <Flex flexDirection='column' >
                        {display}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <SiCashapp size={20} />
                            <Text ml="2" as="b" fontSize="17px">
                                {formatLocale(totalBalance.toString(), token)}
                            </Text>
                        </div>
                    </Flex>
                    
                    </>
                )
            }
        </Box>
    );
}

function AccountInfo() {
    const { isDesktop, userInfo, tokenSale, account } = useEthersContext();
    const totalTokenInUSD = BigNumber.from(tokenSale.data.config.tokenPrice)
        .mul(
            BigNumber.from(tokenSale.data.user.balance)
                .add(BigNumber.from(tokenSale.data.user.totalUserBonus))
                .add(
                    BigNumber.from(
                        tokenSale.data.user.isIB ||
                            tokenSale.data.config.ibIIBUsers.includes(account!)
                            ? tokenSale.data.user.ibBonus
                            : 0
                    )
                )
        )
        .div(1000);
    if (userInfo.id === "") {
        return <></>;
    } else
        return (
            <Box
                w="full"
                h="auto"
                borderRadius={selectBorder(isDesktop)}
                p={selectPadding(isDesktop)}
                backgroundColor="#2B2D3C"
                color="white"
            >
                <AccountInfoHeader totalTokenInUSD={totalTokenInUSD} />
                <Divider />
                {isDesktop ? (
                    <HStack
                        w="full"
                        h="auto"
                        spacing="20px"
                        p={2}
                        alignItems="center"
                        textAlign="center"
                    >
                        <AccountInfoAbove />
                        <AccountInfoBelow />
                        <AccountInfoBottom totalTokenInUSD={totalTokenInUSD} />
                    </HStack>
                ) : (
                    <>
                        <HStack
                            w="full"
                            h="auto"
                            spacing={5}
                            p={2}
                            justifyContent="center"
                            textAlign="center"
                        >
                            <AccountInfoAbove />
                        </HStack>
                        <HStack
                            w="full"
                            h="auto"
                            spacing={5}
                            p={2}
                            justifyContent="center"
                            textAlign="center"
                        >
                            <AccountInfoBelow />
                        </HStack>
                        <HStack
                            w="full"
                            h="auto"
                            spacing={5}
                            p={2}
                            justifyContent="center"
                            textAlign="center"
                        >
                            <AccountInfoBottom
                                totalTokenInUSD={totalTokenInUSD}
                            />
                        </HStack>
                    </>
                )}
            </Box>
        );
}

export default AccountInfo;
