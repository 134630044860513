import React from 'react'
import {
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { RiAdminFill, RiHomeHeartFill } from 'react-icons/ri'
import { logo } from '../assets'
import { useNavigate } from 'react-router-dom'
import { useEthersContext } from '../context'
import { FaExchangeAlt } from 'react-icons/fa'
import { IoIosGitNetwork } from 'react-icons/io'
import { BsCoin } from 'react-icons/bs'
import { UnderConstructionModal } from './Modal'
function Menu({isOpenMenu, onCloseMenu} : {isOpenMenu: boolean, onCloseMenu: ()=> void}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isManagerAccess } = useEthersContext()
  const navigate = useNavigate()
  return (
    <>
      <Drawer
        isOpen={isOpenMenu}
        placement='right'
        onClose={onCloseMenu}
        variant='primary'
      >
        <CloseButton />
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader><Image w='auto' h='45px' src={logo} alt='logo'/></DrawerHeader>
          <DrawerBody>
            <Button leftIcon={<RiHomeHeartFill color='white' />} variant='ghost' onClick={()=> { navigate('user'); onCloseMenu(); } }>
              <Text color='white'>Home</Text>
            </Button>
            <Divider color='gray.200'/>
            <Button leftIcon={<IoIosGitNetwork color='white' />} variant='ghost' onClick={()=> { navigate('network'); onCloseMenu(); }}>
              <Text color='white'>Network</Text>
            </Button> 
            <Divider color='gray.200'/>
            <Button leftIcon={<FaExchangeAlt color='white' />} variant='ghost' onClick={()=> { onOpen();; onCloseMenu(); }}>
              <Text color='white'>Exchange</Text>
            </Button> 
            <Divider color='gray.200'/>
            <Button leftIcon={<BsCoin color='white' />} variant='ghost' onClick={()=> { navigate('token'); onCloseMenu(); }}>
              <Text color='white'>Token</Text>
            </Button> 
            <Divider color='gray.200'/>
            { isManagerAccess && <Button leftIcon={<RiAdminFill color='white' />} variant='ghost' onClick={()=> { navigate('admin'); onCloseMenu(); }}>
              <Text color='white'>Admin</Text>
            </Button> }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <UnderConstructionModal 
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export default Menu
